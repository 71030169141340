import React from 'react';
import useStyles from './../../styles/footer';
import { Typography, Grid } from '@mui/material';
import { getIsMobile } from '../../utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
	const classes = useStyles();
	const isMobile = getIsMobile();
	const { t } = useTranslation();
	const changeActiveHeader = (prop) => {
		localStorage.setItem('activeHeader', prop);
	  };
	  const scrollFunc=(value)=>{
		sessionStorage.setItem('accoPanel',value)
		window.scrollTo(0,0)
	  }
	return (
		<>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				spacing={isMobile ? 0 : 8}
				className={classes.footerSec}
			>
				<Grid
					item
					xs={12}
					md={4}
					style={{
						alignItems: isMobile ? 'center' : '',
					}}
				>
					<Link to="/">
						<img src="/assets/images/logo_black.png" alt="footer logo" />
					</Link>
					<Typography variant="subtitle2" component="p">
						{t("para7")}
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
					style={{ margin: isMobile ? '20px 0px' : '' }}
				>
					<Grid container justifyContent="space-around">
						<Grid item xs={6}>
							<Typography variant="subtitle1" component="p">
							{t("aboutUs")}
							</Typography>
							<a



								href="https://play.google.com/store/apps/details?id=com.iseeju"

								className={classes.links}
								onClick={() => changeActiveHeader('features')}
							>
							{t("features")}
							</a>

							<br/>
							<Link
								variant="subtitle2"
								underline="none"
								color="#000000"
								to="/faq"
								sx={{ marginTop: '10px' }}
								className={classes.links}
							>
							{t("faq")}
							</Link>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="subtitle1" component="p">
							{t("sonstiges")}
							</Typography>
							<Link
								variant="subtitle2"
								underline="none"
								color="#000000"
								to="about-us"
								sx={{ marginTop: '10px' }}
								className={classes.links}
								onClick={()=>scrollFunc('panel1')}
							>
								{t("aboutUs")}
							</Link>
							<br />
							<Link
								variant="subtitle2"
								underline="none"
								color="#000000"
								to="about-us"
								sx={{ marginTop: '10px' }}
								className={classes.links}
								onClick={()=>scrollFunc('panel2')}
							>
							{t("termsAndcondition")}
							</Link>
							<br/>
							<Link
								variant="subtitle2"
								underline="none"
								color="#000000"
								to="about-us"
								sx={{ marginTop: '10px' }}
								className={classes.links}
								onClick={()=>scrollFunc('panel3')}
							>
								{t("privacyPolicy")}
							</Link>
							<br />
							<Link
								variant="subtitle2"
								underline="none"
								color="#000000"
								to="about-us"
								sx={{ marginTop: '10px' }}
								className={classes.links}
								onClick={()=>scrollFunc('panel4')}
							>
							{t("cookiesPolicy")}
							</Link>
							<br />
							<Link
								variant="subtitle2"
								underline="none"
								color="#000000"
								to="about-us"
								sx={{ marginTop: '10px' }}
								className={classes.links}
								onClick={()=>scrollFunc('panel5')}
							>
							{t("impressum")}
							</Link>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={4}>
					<div>
						<Typography variant="subtitle1" component="p">
							{t("weAresocial")}
						</Typography>

						<Link  to="#">
							<img
								src="/assets/icons/ico_instagram.png"
								alt="instagram"
								className={classes.instagram}
							/>
						</Link>
						<Link  to="#">
							<img
								src="/assets/icons/ico_facebook.png"
								alt="facebook"
								className={classes.instagram}
							/>
						</Link>
						<Link  to="#">
							<img
								src="/assets/icons/ico_twitter.png"
								alt="Twitter"
								className={classes.instagram}
							/>
						</Link>
						<Link to="#">
							<img
								src="/assets/icons/ico_yt.png"
								alt="Youtube"
								className={classes.instagram}
							/>
						</Link>
						{isMobile ? (
							<Link to="/">
								<img
									src="/assets/icons/ico_payment.png"
									alt="PayPAl"
									style={{ marginLeft: '0px', marginTop: '5px' }}
								/>
							</Link>
						) : null}
					</div>
					{isMobile ? null : (
						<Link to="/">
							<img
								src="/assets/icons/ico_payment.png"
								alt="PayPAl"
								style={{ marginTop: '20px' }}
							/>
						</Link>
					)}
				</Grid>
			</Grid>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				className={classes.copyright}
			>
				<Typography variant="subtitle2" component="p" sx={{ color: '#fff' }}>
					© 2022 Copyright iseeju All Rights Reserved.
				</Typography>
				<p hidden>09-06-2022</p>
			</Grid>
		</>
	);
};
export default Footer;
