import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Typography,
  Grid,
  Button,
  FormControl,
  FormGroup,
  TextField,
  InputAdornment,
  IconButton,
  Chip,
  Card,
  CardContent,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import useStyles from '../styles/auth';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as registerAction from '../redux/action/registerAction';
import { ReactComponent as GmailIcon } from '../components/icons/Gmail.svg';
import CancelIcon from '@mui/icons-material/Cancel';
import Loader from '../utils/loader';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { initializeApp } from 'firebase/app';
import { getAuth, TwitterAuthProvider, signInWithPopup,FacebookAuthProvider,GoogleAuthProvider } from "firebase/auth";
import { firebaseConfig } from '../configs';
const Register = (props) => {
  const app = initializeApp(firebaseConfig);
  const twitterProvider = new TwitterAuthProvider();
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const auth = getAuth();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [passwordVisibility, showPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signupMessage, setSignupMessage] = useState('');
  const [errorMessages, setErrorMessages] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSocialRegistration, setIsSocialRegistration] = useState(false);
  const validationSchema = () => {
    if (!isSocialRegistration) {
      return Yup.object().shape({
        email: Yup.string()
          .email('Invalid email format!')
          .required(t("emailRequired")),

        password: Yup.string()
          .required('Password is required!')
          .matches(
            '^(?=.{6,})',
            t("passwordCategory")
          ),
      });
    } 
    else {
      return Yup.object().shape({
        password: Yup.string()
          .required('Password is required!')
          .matches(
            '^(?=.{6,})',
            t("passwordCategory")
          ),
      });
    }

  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        setErrorMessages({});
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    setErrorMessages(
      validationError.inner.reduce((errors, error) => {
        return {
          ...errors,
          [error.path]: error.errors[FIRST_ERROR],
        };
      }, {})
    );
    // return validationError.inner.reduce((errors, error) => {
    //   return {
    //     ...errors,
    //     [error.path]: error.errors[FIRST_ERROR],
    //   };
    // }, {});
  };

  const getInitialValues = () => {
    const initialValues = {
      email: '',
      password: '',
    };
    return initialValues;
  };

  const handleSubmit = (values) => {
    if (!isSocialRegistration) {
      if (Object.keys(errorMessages).length === 0) {
        setIsLoading(true);
        setEmail(values.email);
        setPassword(values.password);
        let data = new FormData();
        data.append('email', values.email);
        props.verifyUserByEmail(data);
      }
    } else {
      setPassword(values.password);
      localStorage.setItem('email', email);
      localStorage.setItem('password', values.password);
      history.push('/create-profile');
    }

  };

  useEffect(() => {
    if (props.emailStatus === 'Email is already registered.') {
      setIsLoading(false);
      setSignupMessage(props.emailStatus);
      setTimeout(() => {
        history.push('/login');
      }, 5000);
      setTimeout(() => {
        props.uniqueStatusNull();
      }, 6000);
    } else if (props.emailStatus === 'Email not exist' && !isSocialRegistration) {
      setTimeout(() => {
        props.uniqueStatusNull();
      }, 6000);
      setIsLoading(false);
      localStorage.setItem('email', email);
      localStorage.setItem('socialID', '');
      localStorage.setItem('socialType', '');
      localStorage.setItem('password', password);
      history.push('/create-profile');
    } else if (props.emailStatus === 'Email not verified') {
      setTimeout(() => {
        props.uniqueStatusNull();
      }, 6000);
      setIsLoading(false);
      setSignupMessage('Email already exist but Not verified.');
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
      setTimeout(() => {
        setSignupMessage('');
      }, 4000);
      setTimeout(() => {
        history.push('/verify-otp');
      }, 5000);
    }
  }, [props.emailStatus]);

  const closeError = () => {
    setSignupMessage('');
    setErrorMessages({});
  };
  const responseGoogle = (response) => {
    console.log(response.profileObj.googleId);
    setIsSocialRegistration(true)
    let data = new FormData();
    setEmail(response.profileObj?.email);
    localStorage.setItem('socialID', response.profileObj.googleId);
    localStorage.setItem('socialType', 'google');
    data.append('email', response.profileObj?.email);
    props.verifyUserByEmail(data);
  };
  const responseError = (response) => {
    console.log(response);
  };
  const responseFacebook = (response) => {
    axios.get(`https://graph.facebook.com/me?fields=id,name,email&access_token=${response.accessToken}`)
      .then((response) => {
        if (response.data) {
          setIsSocialRegistration(true)
          let data = new FormData();
          setEmail(response.data.email);
          localStorage.setItem('socialID', response.data.id);
          localStorage.setItem('socialType', 'facebook');
          data.append('email', response.data.email);
          props.verifyUserByEmail(data);

        }
      })
      .catch((error) => {
        console.log(error);
      })
  };

  const authHandler = (provider,title) => {
    signInWithPopup(auth, provider)
      .then((result) => {
        //const credential = TwitterAuthProvider.credentialFromResult(result);
        const user = result.user;
        setIsSocialRegistration(true)
        let data = new FormData();
        setEmail(user.email);
        localStorage.setItem('socialID', user.uid);
        localStorage.setItem('socialType', title);
        data.append('email', user.email);
        props.verifyUserByEmail(data);
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = TwitterAuthProvider.credentialFromError(error);
        console.log(error);
        // ...
      });
  };

  
  return (
    <div className={classes.bannerBgImage}>
      {isLoading ? <Loader /> : ''}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={8} md={4} sx={{ marginTop: '-100px' }}>
          <Typography variant="h1" component="h1">
           {t("createAccount")}
          </Typography>
          <div>
            <Link to="#">
              <GoogleLogin
                clientId="564348909029-f0062k8qg8fe5u20lplb2dhguch636fu.apps.googleusercontent.com"
                render={(renderProps) => (
                  <GmailIcon
                    className={classes.instagram}
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  />
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseError}
                cookiePolicy={'single_host_origin'}
              />
            </Link>
            <Link to="#">
              <FacebookLogin
                appId="699443281105756"
                autoLoad={false}
                callback={responseFacebook}
                render={(renderProps) => (
                  <img
                    src="/assets/icons/ico_facebook_1.png"
                    alt="facebook"
                    className={classes.instagram}
                    onClick={renderProps.onClick}
                  />
                )}
              />
            </Link>
            {/* <Link to="#" >
              <GmailIcon
                alt="Google"
                className={classes.instagram}
                onClick={() => authHandler(googleProvider,"google")}
              />
            </Link>
            <Link to="#" >
              <img
                src="/assets/icons/ico_facebook_1.png"
                alt="Facebook"
                className={classes.instagram}
                onClick={() => authHandler(facebookProvider,"facebook")}
              />
            </Link> */}
            <Link to="#">
              <img
                src="/assets/icons/ico_twitter_1.png"
                alt="Twitter"
                className={classes.instagram}
                onClick={() => authHandler(twitterProvider,"twitter")}
              />
            </Link>
          </div>
          <Typography variant="h5" component="h5" sx={{ marginBottom: '15px' }}>
           {t("orUseEmail")}
          </Typography>
          <Formik
            initialValues={getInitialValues()}
            validate={validate(validationSchema)}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            render={({
              errors,
              handleChange,
              handleSubmit,
              values,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormControl style={{ width: '80%' }}>
                  <FormGroup aria-label="position">
                    {/* {error && (
								<div style={{ margin: '10px 0px' }}>
									<Alert severity="error">{error}</Alert>
								</div>
							)} */}

                    {!isSocialRegistration ? <TextField
                      variant="filled"
                      name="email"
                      placeholder={t("enterEmail")}
                      autoComplete="off"
                      value={values.email}
                      onChange={handleChange}
                      helperText={
                        errors.email &&
                        touched.email && (
                          <span className={classes.errorText}>
                            {errors.email}
                          </span>
                        )
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                          root: classes.inputRoot,
                        },
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.startAdorn}
                          >
                            <EmailIcon className={classes.mailIcon} />
                          </InputAdornment>
                        ),
                      }}
                      margin="normal"
                      fullWidth
                    /> : ''}
                    <TextField
                      autoComplete="off"
                      variant="filled"
                      name="password"
                      type={passwordVisibility ? 'text' : 'password'}
                      placeholder={t("enterPassword")}
                      value={values.password}
                      onChange={handleChange}
                      helperText={
                        errors.password &&
                        touched.password && (
                          <span className={classes.errorText}>
                            {errors.password}
                          </span>
                        )
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                          root: classes.inputRoot,
                        },
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.startAdorn}
                          >
                            <LockIcon className={classes.lockIcon} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => showPassword(!passwordVisibility)}
                              edge="end"
                              sx={{ mr: 1 }}
                            >
                              {passwordVisibility ? (
                                <VisibilityIcon style={{ color: '#fff' }} />
                              ) : (
                                <VisibilityOffIcon style={{ color: '#fff' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off',
                        },
                      }}
                      margin="normal"
                      fullWidth
                    />
                    {Object.keys(errorMessages).length > 0 &&
                      errorMessages.password != undefined ? (
                      <>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            color: '#fbfbe9',
                            fontWeight: 400,
                            marginTop: '8px',
                          }}
                        >
                         {t("passwordShouldcontainAtleast8Character")}
                        </Typography>
                      </>
                    ) : (
                      ''
                    )}

                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ borderRadius: '10px', margin: '60px 0px ' }}
                      type="submit"
                    >
                      {t("signUp")}
                    </Button>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        color: '#fbfbe9',
                        fontWeight: 400,
                        marginTop: '10px',
                      }}
                    >
                   {t("alredyHaveAccLogin")}
                      <Link
                        to="/login"
                        style={{
                          color: '#fbfbe9',
                          textDecoration: 'underline',
                        }}
                      >
                       {t("login")}
                      </Link>
                    </Typography>
                    {signupMessage != '' ||
                      Object.keys(errorMessages).length > 0 ? (
                      <Grid
                        sx={{
                          pt: 0,
                          pb: 0,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '5px',
                            backgroundColor: '#cc493a',
                            border: '#cc493a',
                            ml: 4,
                            mr: 4,
                            mt: 1,
                            color: '#edc9b8',
                            minHeight: '40px',
                            width: 'auto',
                            position: 'absolute',
                            maxHeight: 'auto',
                          }}
                        >
                          <CardContent sx={{ p: '0 !important' }}>
                            <CancelIcon
                              onClick={() => closeError()}
                              style={{
                                cursor: 'pointer',
                                float: 'right',
                                color: 'white',
                              }}
                            />
                          </CardContent>
                          <CardContent>
                            {signupMessage != '' ? (
                              <Typography
                                sx={{
                                  color: '#edc9b8',
                                  fontSize: '12px',
                                  pt: ' 0 !important',
                                  pb: ' 0 !important',
                                }}
                              >
                                {signupMessage}
                              </Typography>
                            ) : (
                              ''
                            )}
                            {errorMessages.email != '' ? (
                              <Typography
                                sx={{
                                  color: '#edc9b8',
                                  fontSize: '12px',
                                  pt: 0,
                                  pb: 0,
                                }}
                              >
                                {errorMessages.email}
                              </Typography>
                            ) : (
                              ''
                            )}
                            {errorMessages.password != '' ? (
                              <Typography
                                sx={{
                                  color: '#edc9b8',
                                  fontSize: '12px',
                                  pt: 0,
                                  pb: 0,
                                }}
                              >
                                {errorMessages.password}
                              </Typography>
                            ) : (
                              ''
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                </FormControl>
              </Form>
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    registerStatus: state.RegisterReducer.data,
    emailStatus: state.RegisterReducer.emailStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyUserByEmail: (requestData) =>
      dispatch(registerAction.verifyUserByEmail(requestData)),
    uniqueStatusNull: () => dispatch(registerAction.uniqueStatusNull()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
