import { Table } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	bannerBgImage: {
		background: 'url(/assets/images/bg.png) no-repeat',
		minHeight: '95vh',
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		paddingLeft: '10vw',
		backgroundPosition: 'bottom',
		[theme.breakpoints.down('lg')]: {
			minHeight: '85vh',
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: '65vh',
		},
		'@media (max-width: 450px)': {
			minHeight: '50vh',
			padding: '13px',
		},
		'@media (max-width: 1200px)': {
			minHeight: '50vh',
			padding: '13px',
		},
	},
	bannerBgImageTab: {
		background: 'url(/assets/images/bg.png) no-repeat',
		minHeight: '85vh',
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		paddingLeft: '2vw',
		backgroundPosition: 'bottom',
		[theme.breakpoints.down('sm')]: {
			minHeight: '65vh',
		},
		'@media (max-width: 450px)': {
			minHeight: '50vh',
			padding: '13px',
		},
	},
	bannerBgImageMob: {
		background: 'url(/assets/images/mob_landing_bg.png) no-repeat',
		minHeight: '95vh',
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '80px',
		backgroundPosition: 'bottom',
		[theme.breakpoints.down('lg')]: {
			minHeight: '85vh',
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: '65vh',
		},
		'@media (max-width: 450px)': {
			minHeight: '100vh',
			padding: '13px',
		},
		'@media (max-width: 768px)': {
			minHeight: '44em',
			padding: '13px',
		},


	},
	sections: {
		padding: '12vh 10vw',
		textAlign: 'center',
	},
	leftSections: {
		paddingLeft: '10vw',
	},
	downloadBg: {
		background: 'url(/assets/images/download_bg.png) no-repeat',
		minHeight: '150vh',
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingRight: '3vw',
		backgroundPosition: 'center',
		[theme.breakpoints.down('md')]: {
			minHeight: '80vh',
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: '50vh',
			padding: '13px',
			position: 'relative',
		},
	},
	downloadText: {
		width: '30vw',
		'@media (max-width: 1068px)': {
			width: '35vw',
		},
		[theme.breakpoints.down('md')]: {
			width: '38vw',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '600px',
			left: '36px',
			textAlign: 'center',
			width: '80vw',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	happyUsersSec: {
		textAlign: 'center',
		padding: '10vh 5vw',
		[theme.breakpoints.down('sm')]: {
			// marginTop: '200px',
		},
	},
	userSec: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '10vh',
	},
	leftArrow: {
		position: 'absolute',
		top: '50%',
		left: '0px',
		cursor: 'pointer',
		zIndex: 10,
		backgroundColor: '#44DAFE',
		height: '30px',
		width: '30px',
		borderRadius: '20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	rightArrow: {
		position: 'absolute',
		top: '50%',
		right: '0px',
		cursor: 'pointer',
		zIndex: 10,
		backgroundColor: '#44DAFE',
		height: '30px',
		width: '30px',
		borderRadius: '20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	arrowIcons: {
		color: '#fff',
		fontSize: '40px',
	},
	arrowIconsCarosual: {
		color: '#fff',
		fontSize: '40px',
		background: '#fe3d73',
		borderRadius: '50%',
	},
	locationIcon: {
		fontSize: '16px',
		marginBottom: '-4px',
	},
	headingSec: {
		width: '40vw',
		display: 'table',
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	playstoreButton: {
		'&:hover': {
			boxShadow:
				'0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);',
			borderRadius: '30px',
		},

	},
	regCardColor: {
		backgroundColor: "#e4f8fa !important",
		margin: '4px',
		borderRadius: '15px',
		height: '406px'
	},
	rightContent: {
		marginTop: '60px',
		marginLeft: '-200px'
	},
	location: {

		'@media (min-width: 1280px)': {
			marginLeft: '-4rem'
		},
	},
	events: {

		'@media (min-width: 1280px)': {
			marginLeft: '-4rem',
		},
	},
	cardSection: {
		minWidth: 350,
		maxWidth: 350,
	},
	tabCardSection: {
		minWidth: 290,
		maxWidth: 290,
	},
	bigCircle: {

	},
	nameContainer: {
		position: 'relative',
		textAlign: 'center',
		color: 'white',
	},
	imageTextAlert: {
		position: 'absolute',

		transform: ' translate(-50%, -50%)',
		'@media (min-width:1561px)': {
			top: '33%',
			left: '-45%',
		},
		'@media (min-width: 1344px) and (max-width:1560px)': {
			top: '30%',
			left: '-23%',
		},
	},
	imageTextMain: {
		position: 'absolute',
		top: '75%',
		left: '50%',
		transform: ' translate(-50%, -50%)',
		color: 'white'
	},
	imageTextSub: {
		position: 'absolute',
		top: '82%',
		left: '50%',
		transform: ' translate(-50%, -50%)',
		color: 'white',
		fontSize: '10px'
	},
	dot: {
		height: "110px",
		width: "110px",
		backgroundColor: '#bbb',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		border: `3px solid #c18cfb`,
	},
	dotMob: {
		height: "75px",
		width: "75px",
		backgroundColor: '#bbb',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		border: `3px solid #c18cfb`,
	},
	textInput: {
		padding: '2px 12px',
		width: '300px',
		height: '40px',
		border: '1px solid #80808082',
		borderRadius: '10px',
	},
	textInputpassword: {
		padding: '2px 12px',
		width: '270px',
		height: '40px',
		border: '1px solid #80808082',
		borderRadius: '10px',
	},
	dateinput: {
		width: '300px',
		height: '40px',
		borderRadius: '10px',
		border: '1px solid #808080a6',
	},
	logo_container: {
		maxWidth: '300px',
		margin: '0 auto',
		marginTop: '37px',
		position: 'relative',
		width: 'auto',
		display: 'block',

	},
	logo_img: {
		textAlign: 'center',

	},
	banner_container: {
		marginTop: '38px',
		//  minHeight: '270px',
		maxWidth: '230px',

		margin: '0 auto',
	},
	banner_img_c: {
		position: 'relative',


	},
	main_cir_bg: {

	},

	main_cir: {
		position: 'absolute',
		top: '20%',
		right: '-2%',
	},
	main_cir2: {
		top: '62%',
		right: '-8%',
		position: 'absolute',

	},
	main_cir3: {
		position: 'absolute',
		top: '71%',
		left: '0',
	},
	main_cir4: {
		position: 'absolute',
		left: '-10%',
		top: '20%',
	},
	main_cir5: {
		position: 'absolute',
		left: '29%',
		bottom: '-4%',
		background: "#fff",
		borderRadius: "50%",
	},
	main_cir6: {
		position: 'absolute',
		bottom: '-5%',
		left: '59%',
	},

	corner_1: {
		position: 'absolute',
		top: '7%',
		right: '0',
		padding: '10px',
	},
	corner_2: {
		position: 'absolute',
		top: '32%',
		left: '0',

	},
	main_cir_h6: {
		fontWeight: '700',
		fontSize: '12px',
		lineHeight: '22px',

		textAlign: 'center',

		color: '#FFFFFF',
		margin: '0',
	},
	main_cir_text: {
		position: 'absolute',
		top: '72%',
		left: '0',
		right: '0',
	},
	main_text_p: {
		fontWeight: '400',
		fontSize: '8px',
		lineHeight: '20px',
		textAlign: 'center',
		color: '#FFFFFF',
		margin: '0',
	},
	button_box: {
		marginTop: '27px',
		textAlign: 'center',

	},
	faceb_login: {
		width: '292px',
		margin: 'auto',
	},
	twit_login: {
		width: '292px',
		margin: 'auto',
	},
	goog_login: {
		width: '278px',
		// margin: 'auto',
	},
	bord_or: {
		textAlign: 'center',
	},
	join_n: {
		textAlign: 'center',
	},
	bg_mobile: {
		display: 'flex', justifyContent: 'start',
		background: `url('/assets/images/Mob_svg_new.png') no-repeat`,
		width: '100px', height: '490px',
		backgroundSize: 'contain',
		backgroundPosition: '65% 0'
	},
	icon_text: {
		textAlign: 'center',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '24px',
		color: '#000000',
		width: '280px',
	},
	mobCircle: {
		width: 230,
		height: 230,
		borderRadius: "50%",
		display: "inline - block",
		border: "5px solid #aa5eed",
		backgroundSize: 'contain !important',
		//backgroundSize: 'cover',

	},
	mobCicletopRight: {
		width: '31.75px',
		height: '31.75px',
		borderRadius: "50%",
		display: "inline - block",
		backgroundSize: 'contain !important'

	},
	mobCicletopLeft: {
		width: '59.09px',
		height: '59.09px',
		borderRadius: "50%",
		display: "inline - block",
		backgroundSize: 'contain !important',

	},
	mobCiclebottomLeft: {
		width: '36.41px',
		height: '36.41px',
		borderRadius: "50%",
		display: "inline - block",
		backgroundSize: 'contain !important',
	},
	mobCiclebottomRight: {
		width: '55.09px',
		height: '55.09px',
		borderRadius: "50%",
		display: "inline - block",
		backgroundSize: 'contain !important',

	},
	mobCiclethumb: {
		width: '33px',
		height: '33px',
		borderRadius: "50%",
		display: "inline - block",
	},
	mobCicleheart: {
		width: '33px',
		height: '33px',
		borderRadius: "50%",
		display: "inline - block",
	},
	mobCornerTop: {
		width: '42px',
		height: '42px',
		borderRadius: "50%",
		display: "inline - block",
		backgroundSize: 'contain !important',
	},
	mobCornerbottomLeft: {
		width: '27.55px',
		height: '27.55px',
		borderRadius: "50%",
		backgroundSize: 'contain !important',
	},
	clearfix: {
		display: 'flex !important',
		justifyContent: "center !important"
	},
	usernameErr: {
		border: '1px solid red',
	},
	usernameSuccess: {
		border: '1px solid green',
	},
	thumbsUp: {
		width: '50px',
		height: '50px',
		borderRadius: ' 50%',
		display: 'flex',
		top: ' 92%',
		left: '33%',
		position: 'absolute',
		background: 'white',
		justifyContent: 'center',
		alignItems: 'center',
	},
	Heart: {
		width: '50px',
		height: '50px',
		borderRadius: ' 50%',
		display: 'flex',
		top: ' 92%',
		left: '57%',
		position: 'absolute',
		background: 'white',
		justifyContent: 'center',
		alignItems: 'center',
	},
	topAvatar: {
		width: '50px',
		height: '50px',
		borderRadius: ' 50%',
		display: 'flex',
		top: ' -12%',
		left: '100%',
		position: 'absolute',
		background: 'white',
		justifyContent: 'center',
		alignItems: 'center',
	},
	leftAvatar: {
		width: '50px',
		height: '50px',
		borderRadius: ' 50%',
		display: 'flex',
		top: ' 55%',
		left: '-15%',
		position: 'absolute',
		background: 'white',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.up('sm')]: {
			top: ' 50%',
			left: '-20%',
		},
		[theme.breakpoints.up('md')]: {
			top: ' 50%',
			left: '-20%',
		},
		[theme.breakpoints.down('md')]: {
			top: ' 50%',
			left: '-20%',
		},

	},
	IconInside: {
		position: 'relative',
		width: '400px',
	},
	iconEye: {
		position: 'absolute',
		left: '85%',
		top: '2px',
		padding: '10px 10px',
		// color: '#30A3F1',
	}

}));

export default useStyles;
