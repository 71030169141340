import { handleActions } from 'redux-actions';

const ChatReducer = {
  chatHistory: [],
  postMessageStatus: null,
  recentMessages: [],
  newChatConnectionStatus: null,
  stickerList: [],
  nextMessageStatus:null,
  recordDuration:0,
  luckyChatStatus:null,
  messageCount:null,
  stickerUnlocked:false,
  voiceUnlocked:false,
  oldMessages:[],
  recentEmojiesList:[],
    deleteChatEvent: false,
};

const reducer = handleActions(
  {
    GET_CHAT_HISTORY_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_CHAT_HISTORY_SUCCESS: (state, action) => {
      return {
        ...state,
        chatHistory: action.payload.message,
        stickerList:action.payload.sticker_list,
        nextMessageStatus:action.payload.msg_status,
        recordDuration:action.payload.voice_limit,
        stickerUnlocked:action.payload.sticker_feature_unlock==='Yes'?true:false,
        voiceUnlocked:action.payload.voice_feature_unlock==='Yes'?true:false,
      };
    },
    GET_CHAT_HISTORY_FAILURE: (state) => {
      return {
        ...state,
        chatHistory: [],
        stickerList:[]
      };
    },
      DELETE_CHAT_INITIATE: (state) =>{
        return {
            ...state,
            deleteChatEvent: false,
        }
      },
      DELETE_CHAT_SUCCESS: (state) => {
        return {
            ...state,
            chatHistory: [],
            recentMessages:[],
            postMessageStatus:null,
            oldMessages:[],
            deleteChatEvent: true,
        }
      },
      DELETE_CHAT_FAILURE: (state) => {
        return {
            ...state,
            deleteChatEvent: false,
        }
      },

    GET_LATEST_MESSAGE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_LATEST_MESSAGE_SUCCESS: (state, action) => {
      return {
        ...state,
        recentMessages: action.payload,
      };
    },
    GET_LATEST_MESSAGE_FAILURE: (state) => {
      return {
        ...state,
      };
    },
    GET_OLD_MESSAGE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_OLD_MESSAGE_SUCCESS: (state, action) => {
      return {
        ...state,
        oldMessages:action.payload
      };
    },
    GET_OLD_MESSAGE_FAILURE: (state) => {
      return {
        ...state,
        oldMessages:'noMessages'
      };
    },
    READ_MESSAGE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    READ_MESSAGE_SUCCESS: (state, action) => {
      return {
        ...state,
        messageCount:action.payload.response
      };
    },
    READ_MESSAGE_FAILURE: (state) => {
      return {
        ...state,
      };
    },
    POST_NEW_MESSAGE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    POST_NEW_MESSAGE_SUCCESS: (state, action) => {
      return {
        ...state,
        postMessageStatus: action.payload.message.created_at,
       nextMessageStatus:action.payload.next_msg_status
      };
    },
    POST_NEW_MESSAGE_FAILURE: (state,action) => {
      return {
        ...state,
        postMessageStatus:  action.payload.message,
        nextMessageStatus:action.payload.next_msg_status
      };
    },
    NEW_CHAT_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    NEW_CHAT_SUCCESS: (state, action) => {
      return {
        ...state,
        newChatConnectionStatus: true,
      };
    },
    NEW_CHAT_FAILURE: (state) => {
      return {
        ...state,
        newChatConnectionStatus: false,
      };
    },
    LUCKY_CHAT_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    LUCKY_CHAT_SUCCESS: (state, action) => {
      return {
        ...state,
        luckyChatStatus: action.payload,
      };
    },
    LUCKY_CHAT_FAILURE: (state) => {
      return {
        ...state,
        luckyChatStatus: false,
      };
    },
    SET_NEW_CHAT_STATUS_SUCCESS: (state) => {
      return {
        ...state,
        newChatConnectionStatus: null,
        luckyChatStatus: null,
        messageCount:null,
        chatHistory:[],
        recentMessages:[],
        postMessageStatus:null,
        oldMessages:[]
      };
    },
    ACTIVE_INACTIVE_INITIATE: (state, action) => {
      return {
        ...state,

      };
    },
    ACTIVE_INACTIVE_SUCCESS: (state) => {
      return {
        ...state,

      };
    },
    ACTIVE_INACTIVE_FAILURE: (state) => {
      return {
        ...state,

      };
    },
    RECENT_EMOJIES_INITIATE: (state, action) => {
      return {
        ...state,
      };
    },
    RECENT_EMOJIES_SUCCESS: (state,action) => {
      return {
        ...state,
        recentEmojiesList:action.payload
      };
    },
    RECENT_EMOJIES_FAILURE: (state) => {
      return {
        ...state,
        recentEmojiesList:[]
      };
    },
  },
  ChatReducer
);
export default reducer;
