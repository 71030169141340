import React, { useEffect, useRef, useState } from "react";
import AppLayout from "../layouts/appLayout";
import HowItWorks from "../components/landingPage/howItWorks";
import Download from "../components/landingPage/download";
import WhyUs from "../components/landingPage/whyUs";
import WhyUsSecond from "../components/landingPage/whyUsSecond";
import HappyUsers from "../components/landingPage/happyUsers";
import Banner from "../components/landingPage/banner";
import useStyles from "../styles/landingPage";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import * as cookiePolicyAction from "../redux/action/cookiepolicyAction";
//import * as commonAction from '../redux/action/commonAction';
import {
  Button,
  Typography,
  Box,
  Drawer,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getIsMobile } from "../utils";
const LandingPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [cookiePolicy, setCookiePolicy] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userToken") != null) {
      history.push("/newest-members");
    }
  }, [localStorage.getItem("userToken")]);

  useEffect(() => {
    if (props.logoutStatus) {
      window.location.reload();
    }
  }, [props.logoutStatus]);

  useEffect(() => {
    if (localStorage.getItem("activeHeader")) {
      var el = document.querySelector(
        "." + localStorage.getItem("activeHeader")
      );
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [localStorage.getItem("activeHeader")]);

  useEffect(() => {
    props.getCookieStatusService();
  }, []);

  useEffect(() => {
    if (
      props.getCookieMessage != "Existing User" &&
      props.getCookieMessage != null
    ) {
      setCookiePolicy(true);
    }
  }, [props.getCookieMessage]);

  const acceptCookies = () => {
    props.postCookieStatusService();
  };

  const learnMore = () => {
    setCookiePolicy(false);
    history.push("/login");
  };

  useEffect(() => {
    if (props.postCookieMessage === "Success") {
      setCookiePolicy(false);
    }
  }, [props.postCookieMessage]);

  useEffect(() => {
    if (localStorage.getItem("email") != null) {
      localStorage.removeItem("email");
    }
  }, [localStorage.getItem("email")]);

  return (
    <AppLayout>
      <Banner />
      <div className="howItWorks">
        <div className={!getIsMobile()?classes.sections:""}>
          <HowItWorks />
        </div>
      </div>
      {/* <div className="features">
        <div className={classes.leftSections}>
          <WhyUs />
        </div>
      </div>

      <div className={classes.sections}>
        <WhyUsSecond />
      </div> */}

      {/* <Download /> */}
      {/* <div className="happyClients">
        <HappyUsers />
      </div> */}

      <div>
        <Drawer
          anchor={"bottom"}
          open={cookiePolicy}
          onClose={() => setCookiePolicy(false)}
        >
          <Box
            sx={{
              width: "auto",
              height: "auto",
            }}
            role="presentation"
          >
            <Typography
              style={{ paddingLeft: "40px", margin: "12px" }}
              gutterBottom
              variant="h3"
            >
              {t("cookiePolicy")}
            </Typography>
            <Typography
              style={{ paddingLeft: "54px" }}
              variant="body2"
              color="text.secondary"
            >
              {t("cookiePolicyContent")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginTop: "12px",
                marginBottom: "10px",
                textTransform: "capitalize",
                borderRadius: "10px",
                cursor: "pointer",
                marginLeft: "54px",
              }}
              onClick={() => acceptCookies()}
            >
              {t("acceptCookies")}
            </Button>

            <Button
              variant="outlined"
              sx={{
                marginTop: "12px",
                marginBottom: "10px",
                textTransform: "capitalize",
                borderRadius: "10px",
                cursor: "pointer",
                marginLeft: "20px",
              }}
              onClick={() => learnMore()}
            >
              {t("learnMore")}
            </Button>
          </Box>
        </Drawer>
      </div>
    </AppLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.LoginReducer.userToken,
    getCookieMessage: state.CookiePolicyReducer.getCookieMessage,
    postCookieMessage: state.CookiePolicyReducer.postCookieMessage,
    logoutStatus: state.LoginReducer.logoutStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCookieStatusService: () =>
      dispatch(cookiePolicyAction.getCookieStatusService()),
    postCookieStatusService: () =>
      dispatch(cookiePolicyAction.postCookieStatusService()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
