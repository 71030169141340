import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UserLayout from '../layouts/userLayout';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  styled,
  Badge,
  Pagination,
} from '@mui/material';
import { ReactComponent as HeartIcon } from '../components/icons/ico_heart_active.svg';
import { ReactComponent as HeartIconInActive } from '../components/icons/ico heart inactive.svg';
import useStyles from '../styles/newestMembers';
import { useHistory } from 'react-router';
import * as commonAction from '../redux/action/commonAction';
import * as loginAction from '../redux/action/loginAction';
import * as lastVisitorAction from '../redux/action/lastVisitorAction';
import { ReactComponent as Level1 } from '../components/icons/DarkBlue Like.svg';
import { ReactComponent as Level2 } from '../components/icons/litebluelike.svg';
import { ReactComponent as Level3 } from '../components/icons/ico like green.svg';
import { ReactComponent as Level4 } from '../components/icons/orange_like.svg';
import { ReactComponent as Level5 } from '../components/icons/like_purple.svg';
import { ReactComponent as GrayLike } from '../components/icons/ico like_gray.svg';
import { ReactComponent as ChatIcon } from '../components/icons/ico_message.svg';
import { ReactComponent as HotIcon } from '../components/icons/hot.svg';
import { ReactComponent as TimerSmallIcon } from '../components/icons/ico_timer_small.svg';
import * as chatAction from '../redux/action/chatAction';
import ConfirmationAlert from '../components/common/confirmationAlert';
import Loader from '../utils/loader';
import { useTranslation } from 'react-i18next';
import RegisterConfirmation from '../utils/RegisterConfirmation';
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    bottom: '50%',
    right: '-30%',
  },
}));
const Newestmembers = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [newestMember, setNewestMember] = useState([]);
  const [newestMemberAlert, setNeweastMemberAlert] = useState(false);
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);
  const [alertCloseButton, setAlertCloseButton] = useState(false);
  const [alertConfirmButtonText, setAlertConfirmButtonText] = useState('');
  const [alertCloseButtonText, setAlertCloseButtonText] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageDatalimit, setPageDatalimit] = useState(15);
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [agbAlert,setAGBAlert]=useState(null)
  useEffect(() => {
    if (localStorage.getItem('userToken') === null) {
      history.push('/');
    }
  }, [localStorage.getItem('userToken')]);

  useEffect(() => {

    let pageNo=sessionStorage.getItem('newPageNo')===null?1:parseInt(sessionStorage.getItem('newPageNo'));
 
    if(pageNo===1){
      setTimeout(() => {
        props.newestMember(localStorage.getItem('userToken'), pageDatalimit, pageNo - 1);
      }, 1000);
      setTimeout(() => {
        setInitialLoading(false)
      }, 2000);
    }else{
      setPage(pageNo)
    }
   
  }, []);

  useEffect(() => {
    // if(page>1){
      props.newestMember(localStorage.getItem('userToken'), pageDatalimit, page - 1);
      setTimeout(() => {
        setInitialLoading(false)
      }, 2000);
    // }     
  }, [page]);

  useEffect(() => {
    if (props.newestMemberData.length > 0) {
      setNewestMember(props.newestMemberData);
    }
  }, [props.newestMemberData]);

  const getUserData = (e, id) => {

    e.stopPropagation();
    props.setCommonStatusNull();
    localStorage.setItem('currentID', id);
    props.getUserByID(localStorage.getItem('userToken'), id);
    props.addLastVisit(id, localStorage.getItem('userToken'));
    history.push('/user-profile');
  };
  const handleFavorite = (e, item) => {
    setIsLoading(true)
    e.stopPropagation();
    let data = new FormData();
    data.append('member_id', item.id);
    data.append('action', item.is_favourite === 1 ? 0 : 1);
    props.addorRemoveFavorite(data);
  };
  const handleLike = (e, item) => {

    e.stopPropagation();
    if (item.is_liked_level <= 4) {
      setIsLoading(true)
      let data = new FormData();
      data.append('member_id', item.id);
      data.append('action', 1);
      data.append(
        'level',
        item.is_liked_level <= 4 ? item.is_liked_level + 1 : ''
      );
      props.likeorDislike(data);
    } else {
      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle('');
      setAlertText(t("likeLevelReached"));
    }
  };

  useEffect(() => {

    if (props.favoriteStatus) {
      setIsLoading(false)
      props.newestMember(localStorage.getItem('userToken'), pageDatalimit, page - 1);
      props.setCommonStatusNull();
    }
  }, [props.favoriteStatus]);

  useEffect(() => {

    if (props.likeStatus) {
      setIsLoading(false)
      props.newestMember(localStorage.getItem('userToken'), pageDatalimit, page - 1);
      props.setCommonStatusNull();
    } else if (props.likeStatus === false) {
      setIsLoading(false)
      props.setCommonStatusNull();
      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(t("dontHaveLikePoint"));
      setAlertText(t("earnAndTryLater"));
    }
  }, [props.likeStatus]);

  useEffect(() => { }, [newestMember]);

  const movetoChat = (e, item) => {
    e.stopPropagation();
    localStorage.setItem('currentChatId', item.id);
    let data = new FormData();
    data.append('to_user_id', item.id);
    props.InitiateNewChat(data, localStorage.getItem('userToken'));
  };

  useEffect(() => {
    if (props.newChatConnectionStatus) {
      // props.getChatHistoryData(localStorage.getItem('userToken'))
      props.newChatStatusNull();
      // setTimeout(() => {
      history.push('/chat');
      //  }, 3000);


    }
  }, [props.newChatConnectionStatus]);

  const getIcon = (item) => {
    let icon;
    if (item.is_liked_level === 0) {
      icon = (
        <GrayLike
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 1) {
      icon = (
        <Level1
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 2) {
      icon = (
        <Level2
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 3) {
      icon = (
        <Level3
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 4) {
      icon = (
        <Level4
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 5) {
      icon = <Level5 className={classes.likeIcon} 
      //onClick={(e) => handleLike(e, item)} 
      />;
    }
    return icon;
  };
  const CloseAlert = () => {
    setNeweastMemberAlert(false);
    setAlertConfirmButton(false);
    setAlertCloseButton(false);
    setAlertConfirmButtonText('');
    setAlertCloseButtonText('');
    setAlertTitle('');
    setAlertText('');
  };
  const confirmFunc = () => { };

  const handleChange = (event, value) => {
    setPage(value);
    sessionStorage.setItem('newPageNo',value)
  };
  useEffect(() => {
    if(parseInt(localStorage.getItem("agbStatus"))===0){
      setAGBAlert(true)
    }else if(parseInt(localStorage.getItem("agbStatus"))===1||props.agbStatus===true){
      setAGBAlert(false)
    }
  }, [localStorage.getItem("agbStatus"),props.agbStatus])
  
  return (
    initialLoading ? <UserLayout subHeader={false} subFooter={false} header={true}><Loader /></UserLayout> : <UserLayout subHeader={false} subFooter={false} header={true}>
      {isLoading ? <Loader /> : ''}
      <ConfirmationAlert
        alert={newestMemberAlert}
        confirmButton={alertConfirmButton}
        closeButton={alertCloseButton}
        confirmButtonText={alertConfirmButtonText}
        closeButtonText={alertCloseButtonText}
        alertTitle={alertTitle}
        alertText={alertText}
        closeFunc={CloseAlert}
        confirmFunc={confirmFunc}
        buttonDisabled={buttonDisabled}
      />
      <RegisterConfirmation dialogStatus={agbAlert} registerFunc={props.acceptAGB} closeConfirmation={props.logoutService}/>
      <Typography sx={{ mb: 3 }}>{newestMember.length === 0 ? t("noNewestMember") : t("newestMembers")}</Typography>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
      >
        {newestMember.length > 0
          ? newestMember.map((item) => {
            return (
              <Grid item xs={6} sm={4} md={3} lg={2.3} xl={2.3} key={item.id}>
                <Card
                  className={classes.bannerCard}
                  sx={{ cursor: 'pointer' }}
                >
                  {/* <CardActionArea> */}
                  <CardMedia
                    onClick={(e) => getUserData(e, item.id)}
                    className={classes.hotbidImg}
                    image={`${item.profile_img}`}

                  >
                    {item.is_approved === 0 ? (
                      <TimerSmallIcon
                        className={classes.TimerIcon}
                        onClick={(e) => handleFavorite(e, item)}
                      />
                    ) : ''}
                    {item.is_favourite === 1 ? (
                      <HeartIcon
                        className={classes.heartIcon}
                        onClick={(e) => handleFavorite(e, item)}
                      />
                    ) : (
                      <HeartIconInActive
                        className={classes.heartIcon}
                        onClick={(e) => handleFavorite(e, item)}
                      />
                    )}
                    {getIcon(item)}
                    <div>
                      {item.is_popular === 1 ? <HotIcon className={classes.box1} /> : ''}

                      <div
                        className={classes.userDescription}
                        style={{ minHeight: '90px', maxHeight: '100px' }}
                      >
                        {item.is_online === 1 ? (
                          <Grid
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid item xs={9}>
                              <Typography
                                variant="body1"
                                component="p"
                                className={classes.userName}
                              >
                                {item.username.length > 7 ? `${item.username.slice(0, 7)}...` : item.username},
                                &nbsp;
                                {item.age === null ? '' : item.age}
                                &nbsp;{
                                  item.gender === 'Male'
                                    ? 'M'
                                    : item.gender === 'Female'
                                      ? 'F'
                                      : ''}
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <StyledBadge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                variant="dot"
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <ChatIcon onClick={(e) => movetoChat(e, item)} />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid item>
                              <Typography
                                variant="body1"
                                component="p"
                                className={classes.userName}
                              >
                                {item.username.length > 7 ? `${item.username.slice(0, 7)}...` : item.username},
                                &nbsp; {item.age === null ? '' : item.age}
                                &nbsp;
                                {
                                  item.gender === 'Male'
                                    ? 'M'
                                    : item.gender === 'Female'
                                      ? 'F'
                                      : ''}
                              </Typography>
                            </Grid>
                            <Grid item onClick={(e) => movetoChat(e, item)}>
                              <ChatIcon />
                            </Grid>
                          </Grid>
                        )}
                        <Grid container >
                          <Grid item xs={8.5} display="flex" justifyContent={'start'}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                            Status:
                          </Typography>
                          </Grid>
                          <Grid item xs={3.5} display="flex" justifyContent={'start'}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                            {item.martial_status === ''||item.martial_status === null ? '' : item.martial_status}
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                        <Grid item xs={8.5} display="flex" justifyContent={'start'}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                           {t("lookingFor")}:
                          </Typography>
                          </Grid>
                          <Grid item xs={3.5} display="flex" justifyContent={'start'}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                            {item.looking_for === null || item.looking_for === '' ? '' :t(`${item.looking_for}`)}                          </Typography>

                        </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                            {item.long_description?.length > 0 && item.long_description?.length > 13 ? `${item.long_description.slice(0, 13)}...` : item.long_description}
                          </Typography>
                        </Grid>
                      </div>
                    </div>
                  </CardMedia>
                  {/* <CardContent className={classes.hotbidCard}>
										

										<Favorite className={classes.favoriteIcon} />
									</CardContent> */}
                  {/* </CardActionArea> */}
                  {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
                </Card>
              </Grid>
            );
          })
          : ''}
        {props.pageCount > 15 ? <Grid item xs={12} display='flex' justifyContent='center'>
          <Pagination count={Math.floor(props.pageCount / 15)} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
        </Grid> : ''}
      </Grid>
    </UserLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTab: state.ProfileTabReducer.selectedTab,
    newestMemberData: state.CommonReducer.newestMemberList,
    activeUserData: state.CommonReducer.activeUsersList,
    favoriteStatus: state.CommonReducer.favoriteStatus,
    likeStatus: state.CommonReducer.likeStatus,
    newChatConnectionStatus: state.ChatReducer.newChatConnectionStatus,
    pageCount: state.CommonReducer.pageCount,
    agbStatus:state.CommonReducer.agbStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newestMember: (requestData, limit, offset) =>
      dispatch(commonAction.getNewestMember(requestData, limit, offset)),
    activeUSers: (requestData) =>
      dispatch(commonAction.getActiveUsers(requestData)),
    getUserByID: (token, requestData) =>
      dispatch(commonAction.getUserByID(token, requestData)),
    addorRemoveFavorite: (requestData) =>
      dispatch(commonAction.favoriteOtherMember(requestData)),
    likeorDislike: (requestData) =>
      dispatch(commonAction.likeOtherMember(requestData)),
    addLastVisit: (userId, Token) =>
      dispatch(lastVisitorAction.addUserVisitor(userId, Token)),
    InitiateNewChat: (requestData, token) =>
      dispatch(chatAction.InitiateNewChat(requestData, token)),
    newChatStatusNull: () => dispatch(chatAction.newChatStatusNull()),
    setCommonStatusNull: () => dispatch(commonAction.setCommonStatusNull()),
    getChatHistoryData: (requestData) =>
      dispatch(chatAction.getChatHistoryData(requestData)),
      acceptAGB: (token) => dispatch(commonAction.acceptAGB(token)),
      logoutService: (requestData) =>
      dispatch(loginAction.logoutService(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Newestmembers);
