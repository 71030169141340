import axios from 'axios';
import { createAction } from 'redux-actions';

export const GetChatHistoryInitiate = createAction('GET_CHAT_HISTORY_INITIATE');
export const GetChatHistorySuccess = createAction('GET_CHAT_HISTORY_SUCCESS');
export const GetChatHistoryFailure = createAction('GET_CHAT_HISTORY_FAILURE');

export const getChatHistoryData = (requestData) => {

  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
    },
  };
  return (dispatch) => {
    dispatch(GetChatHistoryInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}previouschats`, config)
      .then((response) => {

        if (response.data.STATUS) {
          dispatch(GetChatHistorySuccess(response.data));
        } else {
          dispatch(GetChatHistoryFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(GetChatHistoryFailure());
        }
      });
  };
};

export const DeleteChatInitiate = createAction('DELETE_CHAT_INITIATE');
export const DeleteChatSuccess = createAction('DELETE_CHAT_SUCCESS');
export const DeleteChatFailure = createAction('DELETE_CHAT_FAILURE');

export const deleteChat = (requestData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {

    dispatch(DeleteChatInitiate());
    axios
        .post(`${process.env.REACT_APP_BACKEND_URL}delete_message_all`, requestData, config)
        .then((response) => {
          if (response.data.STATUS) {
            dispatch(DeleteChatSuccess(response.data));
          } else {
            dispatch(DeleteChatFailure());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
            dispatch(DeleteChatFailure());
          }
        });
  };
};


export const GetLatestMessageInitiate = createAction(
  'GET_LATEST_MESSAGE_INITIATE'
);
export const GetLatestMessageSuccess = createAction(
  'GET_LATEST_MESSAGE_SUCCESS'
);
export const GetLatestMessageFailure = createAction(
  'GET_LATEST_MESSAGE_FAILURE'
);

export const getLatestChatData = (requestData, token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(GetLatestMessageInitiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}load_latest_messages`,
        requestData,
        config
      )
      .then((response) => {

        if (response.data.STATUS) {
          dispatch(GetLatestMessageSuccess(response.data.recent_messages));
        } else {
          dispatch(GetLatestMessageFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(GetLatestMessageFailure());
        }
      });
  };
};

export const GetOldMessageInitiate = createAction('GET_OLD_MESSAGE_INITIATE');
export const GetOldMessageSuccess = createAction('GET_OLD_MESSAGE_SUCCESS');
export const GetOldMessageFailure = createAction('GET_OLD_MESSAGE_FAILURE');

export const getOldChatData = (requestData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(GetOldMessageInitiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}fetch_old_messages`,
        requestData,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetOldMessageSuccess(response.data.old_messages));
        } else {
          dispatch(GetOldMessageFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(GetOldMessageFailure());
        }
      });
  };
};

export const ReadNewMessageInitiate = createAction('READ_MESSAGE_INITIATE');
export const ReadNewMessageSuccess = createAction('READ_MESSAGE_SUCCESS');
export const ReadNewMessageFailure = createAction('READ_MESSAGE_FAILURE');

export const ReadNewMessage = (requestData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(ReadNewMessageInitiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}seened`,
        requestData,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(ReadNewMessageSuccess(response.data));
        } else {
          dispatch(ReadNewMessageFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(ReadNewMessageFailure());
        }
      });
  };
};

export const PostNewMessageInitiate = createAction('POST_NEW_MESSAGE_INITIATE');
export const PostNewMessageSuccess = createAction('POST_NEW_MESSAGE_SUCCESS');
export const PostNewMessageFailure = createAction('POST_NEW_MESSAGE_FAILURE');

export const PostNewMessage = (requestData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type' : `multipart/form-data`,
    },
  };
  return (dispatch) => {
    dispatch(PostNewMessageInitiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}sendmessage`,
        requestData,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(PostNewMessageSuccess(response.data));
        } else {
          dispatch(PostNewMessageFailure(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(PostNewMessageFailure());
        }
      });
  };
};


export const PostNewChatInitiate = createAction('NEW_CHAT_INITIATE');
export const PostNewChatSuccess = createAction('NEW_CHAT_SUCCESS');
export const PostNewChatFailure = createAction('NEW_CHAT_FAILURE');

export const InitiateNewChat = (requestData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(PostNewChatInitiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}new_message`,
        requestData,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(PostNewChatSuccess(response.data.message));
        } else {
          dispatch(PostNewChatFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(PostNewChatFailure());
        }
      });
  };
};


export const PostLuckyChatInitiate = createAction('LUCKY_CHAT_INITIATE');
export const PostLuckyChatSuccess = createAction('LUCKY_CHAT_SUCCESS');
export const PostLuckyChatFailure = createAction('LUCKY_CHAT_FAILURE');

export const LuckyChatService = (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(PostLuckyChatInitiate());
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}lucky_shot`,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(PostLuckyChatSuccess(response.data.time));
        } else {
          dispatch(PostLuckyChatFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(PostLuckyChatFailure());
        }
      });
  };
};

export const chatActiveInActiveInitiate = createAction('ACTIVE_INACTIVE_INITIATE');
export const chatActiveInActiveSuccess = createAction('ACTIVE_INACTIVE_SUCCESS');
export const chatActiveInActiveFailure = createAction('ACTIVE_INACTIVE_FAILURE');

export const chatActiveInActive = (token,activeStatus) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(chatActiveInActiveInitiate());
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}chat_active_user?type=1&active=${activeStatus}`,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(chatActiveInActiveSuccess(response.data));
        } else {
          dispatch(chatActiveInActiveFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(chatActiveInActiveFailure());
        }
      });
  };
};
export const RecentEmojiesInitiate = createAction('RECENT_EMOJIES_INITIATE');
export const RecentEmojiesSuccess = createAction('RECENT_EMOJIES_SUCCESS');
export const RecentEmojiesFailure = createAction('RECENT_EMOJIES_FAILURE');

export const getRecentEmojies = (token,activeStatus) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(RecentEmojiesInitiate());
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}recent_emojis`,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(RecentEmojiesSuccess(response.data.list));
        } else {
          dispatch(RecentEmojiesFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(RecentEmojiesFailure());
        }
      });
  };
};
export const setNewChatStatusNull=createAction("SET_NEW_CHAT_STATUS_SUCCESS");

export const newChatStatusNull = ()=>{
  return (dispatch)=>{
    dispatch(setNewChatStatusNull());
}
}
