import { Backdrop, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import { t } from 'i18next';
import React, { useState, useEffect } from 'react'
import OtpInput from 'react-otp-input'
import { connect } from 'react-redux';
import useStyles from '../styles/auth';
import * as commonAction from '../redux/action/commonAction';
const OtpVerifyAlert = ({ accVerifiedStatus, postEmail, postOTP, resendOTP, postMailStatus, postOTPStatus, removeOTPAlert, setCommonStatusNull, resendOTPStatus }) => {
    const [otp, setOtp] = useState('');
    const classes = useStyles();
    const [userEmail, setUserEmail] = useState('');
    const [userEmailStatus, setUserEmailStatus] = useState(false);
    const [newEmail, setNewEmail] = useState('')
    const [emailErr, setNewEmailErr] = useState('')
    const [otpErr, setOTPErr] = useState('');
    const [resendStatus, setResendStatus] = useState(false)
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userData'))
        let email = userData.user_general_data?.email
        if (email) {
            setUserEmail(email)
            setUserEmailStatus(true)
        } else {
            setUserEmail('')
            setUserEmailStatus(false)
        }

    }, [localStorage.getItem('userData')])

    const submitOTP = () => {
        let data = new FormData();
        data.append("otp", otp)
        postOTP(data, localStorage.getItem('userToken'))
    }

    useEffect(() => {
        if (postOTPStatus) {
            removeOTPAlert()
        } else if (postOTPStatus === false) {
            setOTPErr(t("invalidOTP"))
        }
    }, [postOTPStatus])

    const submitEmail = () => {
        if (newEmail.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            let data = new FormData()
            data.append('email', newEmail)
            setNewEmailErr('')
            postEmail(data, localStorage.getItem('userToken'))
        } else {
            setNewEmailErr(t("invalidEmailType"))
        }
    }

    useEffect(() => {
        if (postMailStatus) {
            setUserEmail(newEmail)
            setUserEmailStatus(true)
            let userData = JSON.parse(localStorage.getItem('userData'))
            userData['user_general_data']['email'] = newEmail;
            localStorage.setItem('userData', JSON.stringify(userData))
            setNewEmailErr(t(""))
        } else if (postMailStatus === false) {
            setNewEmailErr(t("emailAlreadyExist"))
            setUserEmailStatus(false)
        }
    }, [postMailStatus])

    useEffect(() => {
        if (resendOTPStatus) {
            setResendStatus(true)
            setTimeout(() => {
                setResendStatus(false)
                setCommonStatusNull()
            }, 3000);
           
        }
    }, [resendOTPStatus])

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={accVerifiedStatus}

        >
            <Card sx={{ maxWidth: 350 }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>  <Typography sx={{ fontSize: 14 }} >
                            {userEmailStatus ? t("verifyOTP") : t("emailRequiredOTP")}
                        </Typography></Grid>
                        <Grid item xs={12}>{userEmailStatus ?
                            <OtpInput
                                value={otp}
                                onChange={(value) => setOtp(value)}
                                numInputs={6}
                                containerStyle={classes.otpContainer}
                                inputStyle={classes.otpnew}
                                isInputNum={true}
                                hasErrored={true}
                            /> :
                            <><TextField
                                id="outlined-basic"
                                label="Email" type={'email'}
                                variant="outlined"
                                fullWidth
                                sx={{ mb: 2, mt: 3 }}
                                onChange={(e) => setNewEmail(e.target.value)} />

                            </>
                        }
                        </Grid>
                        {resendStatus?
                        <Grid itex xs={12} sx={{ mb: 1 }}> <Typography variant='p' color='error' sx={{ mb: 5 }}>{t("otpsendSuccessfully")}</Typography></Grid>:""}
                        
                        <Grid itex xs={12} sx={{ mb: 1 }}> <Typography variant='p' color='error' sx={{ mb: 5 }}>{emailErr}</Typography></Grid>
                        <Grid itex xs={12} sx={{ mb: 1 }}> <Typography variant='p' color='error' sx={{ mb: 5 }}>{otpErr}</Typography></Grid>
                        <Grid item xs={12}
                            display='flex'
                            justifyContent={'center'}>
                            <Button color="primary" variant='contained' sx={{ borderRadius: '5px' }}
                                onClick={() => userEmailStatus ? submitOTP() : submitEmail()}>{t("verify")}</Button>
                        </Grid>
                        {userEmailStatus ? <Grid itex xs={12} sx={{ mt: 1 }} display='flex'
                            justifyContent={'center'}> <Typography variant='p' color='error' sx={{ mb: 0 }} onClick={() => resendOTP(localStorage.getItem('userToken'))}>{t('resend')}</Typography></Grid> : ""}
                    </Grid>

                    {/* <Typography sx={{ fontSize: 14 }} >
                        {t("verifyOTPSecondary")}
                    </Typography> */}


                </CardContent>
            </Card>
        </Backdrop>
    )
}
const mapStateToProps = (state) => {
    return {
        postMailStatus: state.CommonReducer.postMailStatus,
        postOTPStatus: state.CommonReducer.postOTPStatus,
        resendOTPStatus: state.CommonReducer.resendOTPStatus
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        postEmail: (data, token) =>
            dispatch(commonAction.postEmail(data, token)),
        postOTP: (data, token) =>
            dispatch(commonAction.postOTP(data, token)),
        resendOTP: (token) =>
            dispatch(commonAction.resendOTP(token)),
        setCommonStatusNull: () => dispatch(commonAction.setCommonStatusNull())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpVerifyAlert)
