import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  Select,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useStyles from '../../styles/header';
import { getIsMobile } from '../../utils';
import { connect } from 'react-redux';
import i18n from "i18next";
import { withTranslation } from "react-i18next";
const Header = (props) => {
  const classes = useStyles();
  const isMobile = getIsMobile();
  const [drawer, setDrawer] = useState(false);
  const [headerTextIsActive, setHeaderTextActive] = useState('');
  const [language, setlanguage] = useState('en');
  const { t } = props;
  const changeActiveHeader = (prop) => {
    setDrawer(false);
    setHeaderTextActive(prop);
    localStorage.setItem('activeHeader', prop);
  };

  const handleLanguageChange = (e) => {
    setlanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  useEffect(() => {
    let lang = localStorage.getItem('lang')
    setlanguage(lang === null ? 'gr' : lang);
  }, [])

  useEffect(() => {
  }, [t])



  const renderMobile = () => {
    return (
      <Grid container direction="row" alignItems="center">
        <Grid item alignItems="left">
          <IconButton
            aria-label="menu icon"
            color="primary"
            onClick={() => setDrawer(true)}
          >
            <MenuIcon style={{ fontSize: '32px' }} />
          </IconButton>
        </Grid>
        <Grid item alignItems="center">
          <Link to="/">
            <img
              src="/assets/images/logo_white.png"
              className={`ml-5  ${classes.logoMobile}`}
              alt="header logo"
            />
          </Link>
        </Grid>
      </Grid>
    );
  };

  const renderWeb = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={3}>
          <Link to="/">
            <img src="/assets/images/logo_white.png" alt="header logo" />
          </Link>
        </Grid>
        <Grid item md={4.5}>
          <Grid container>
            <Link
              to="/"
              underline="none"
              color="#fffefe"
              variant="h6"
              className={
                headerTextIsActive === 'howItWorks'
                  ? classes.selectedHeaderLinks
                  : classes.headerLinks
              }
              onClick={() => changeActiveHeader('howItWorks')}
            >
              {t("howItworksHeader")}
            </Link>
            <Link
              to="/"
              underline="none"
              color="#fffefe"
              variant="h6"
              className={
                headerTextIsActive === 'features'
                  ? classes.selectedHeaderLinks
                  : classes.headerLinks
              }
              onClick={() => changeActiveHeader('features')}
            >
              {t("featuresHeader")}
            </Link>
            <Link
              to="/"
              underline="none"
              color="#fffefe"
              variant="h6"
              className={
                headerTextIsActive === 'happyClients'
                  ? classes.selectedHeaderLinks
                  : classes.headerLinks
              }
              onClick={() => changeActiveHeader('happyClients')}
            >
              {t("happyClients")}
            </Link>
          </Grid>
        </Grid>

        <Grid item md={4.5} style={{ textAlign: 'right' }}>
          <>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={language}
              onChange={(e) => handleLanguageChange(e)}
              className={classes.select}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  root: classes.root,
                },
              }}
            >
              <MenuItem value={'en'}>
                {' '}
                <img
                  src="/assets/icons/flag.png"
                  style={{ marginBottom: '-5px' }}
                  alt="header logo"
                />
              </MenuItem>
              <MenuItem value={'gr'}>
                <img
                  src="/assets/icons/flag1.png"
                  style={{ marginBottom: '-5px' }}
                  alt="header logo"
                />
              </MenuItem>
            </Select>
            <Button variant="text">
              <Link
                to="/login"
                style={{ textDecoration: 'none', color: '#fff' }}
              >
                {t("logIn")}
              </Link>
            </Button>{' '}
            <Button variant="contained" color="primary">
              <Link
                to="#"
                style={{ textDecoration: 'none', color: '#fff' }}
              >
                {t("joinNow")}
              </Link>
            </Button>
          </>
          {/* )} */}
        </Grid>
      </Grid>
    );
  };

  return (
    <AppBar position="static" className={classes.appBar} color="secondary">
      <Toolbar style={{ padding: isMobile ? '5px' : '' }}>
        {isMobile || window.innerWidth <= 1280 ? renderMobile() : renderWeb()}
      </Toolbar>
      <Drawer anchor="left" open={drawer} onClose={() => setDrawer(false)}>
        <List>
          <ListItem button key="logo">
            <Link to="/" onClick={() => setDrawer(false)}>
              <img src="/assets/images/logo_black.png" alt="header logo" />
            </Link>
          </ListItem>
          <ListItem button key="how-it-works">
            <Link
              to="/"
              style={{ color: '#0f0f0f', textDecoration: 'none' }}
              onClick={() => changeActiveHeader('howItWorks')}
            >
              {t("howItworksHeader")}
            </Link>
          </ListItem>
          <ListItem key="features">
            <Link
              to="/"
              style={{ color: '#0f0f0f', textDecoration: 'none' }}
              onClick={() => changeActiveHeader('features')}
            >
              {t("featuresHeader")}

            </Link>
          </ListItem>
          <ListItem key="happy-clients">
            <Link
              to="/"
              style={{ color: '#0f0f0f', textDecoration: 'none' }}
              onClick={() => changeActiveHeader('happyClients')}
            >
              {t("happyClients")}
            </Link>
          </ListItem>
          <ListItem key="Login">
            <Link
              to="/login"
              style={{ color: '#0f0f0f', textDecoration: 'none' }}
              onClick={() => setDrawer(false)}
            >
              {t("logIn")}
            </Link>
          </ListItem>
          <ListItem key="register">
            <Link
              to="#"
              style={{ color: '#0f0f0f', textDecoration: 'none' }}
              onClick={() => setDrawer(false)}
            >
              {t("joinNow")}
            </Link>
          </ListItem>
        </List>
      </Drawer>
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.LoginReducer.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
