import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    containerClass: {

        width: "300px",
        height: "250px",
        border: "1px solid #FFF",
        position: "relative",
        borderRadius: '15px',
        boxShadow: "0px 10px -14px 14px #FFF",
         background: 'white',
        color: 'black',
        alignSelf: 'center',


    },

    accordianClass: {
        boxShadow: "0px 10px -14px 14px #FFF",
        background: 'white',
        width: "120px",
        height: "80px",
        position: "absolute",
        left: "50%",
        top: "-85px",
        transform: "translate(-50%,5%)",
        borderTop: '1px solid white',
        borderLeft: "1px solid white",
        borderRight: " 1px solid white",
        borderTopLeftRadius: " 100px",
        borderTopRightRadius: "100px",
        justifyContent: 'center',
        display: 'flex',
        color:"black"
    },
    alertAccordianClass: {
        boxShadow: "0px 10px -14px 14px #FFF",
        background: 'white',
        width: "85px",
        height: "43px",
        position: "absolute",
        left: "50%",
        top: "-46px",
        transform: "translate(-50%,5%)",
        borderTop: '1px solid white',
        borderLeft: "1px solid white",
        borderRight: " 1px solid white",
        borderTopLeftRadius: " 100px",
        borderTopRightRadius: "100px",
        justifyContent: 'start',
        display: 'flex',
        color:"black"
    },
    icon: {
        top: '100px',
        display: 'flex',
        justifyContent: 'center',
        transform: "translateY( -70px)"
    },
    closeicon: {

        // display:'flex',
        position: 'relative',
        top: '-27%',
        float: 'right',
        // transform: "translateY( -70px)",
        marginRight: '5px'
    },
    title: {
        // display:'flex',
        // justifyContent:'center',
        // transform: "translateY( -70px)",
        // position: 'relative',
        // top: '-25%'
        color:'black !important',
        textAlign:'center'
    },
    subheader:{
        color:'black'
    },
    text: {

        position: 'relative',
        top: '-15%',
        textAlign: "center",
    },
    buttonText: {
        position: 'relative',
        top: '15%',
        textAlign: "center",
        // float:'right',
        // display:'flex',
        // justifyContent:'center',
        // transform: "translateY( -50px)",
    }
}));

export default useStyles;
