import React, { useEffect, useState } from 'react';
import { Avatar, Grid, Typography, List, ListItem, ListItemAvatar, ListItemText, IconButton, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LocationIcon } from '../icons/ico_landing_location.svg';
import { ReactComponent as EventIcon } from '../icons/ico_landing_events.svg';
import { ReactComponent as ProfileIcon } from '../icons/ico_landing_profile.svg';
import { ReactComponent as ChatIcon } from '../icons/ico_landing_chat.svg';
import { ReactComponent as AppStore } from '../icons/ico_landing_appstore.svg';
import { ReactComponent as PlayStore } from '../icons/ico_landing_gplay.svg';
import useStyles from '../../styles/landingPage';
import { connect } from 'react-redux';
import * as happyClientsAction from '../../redux/action/happyclientsAction';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
const HowItWorks = ({ recentMembersData, getRecentClientsService }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [membersData, setMembersData] = useState([])
	const [membersCopyData, setMembersCopyData] = useState([])
	useEffect(() => {
		getRecentClientsService()
	}, [])

	useEffect(() => {
		if (recentMembersData) {
			let recentMembers = [], count = 0;
			recentMembersData.forEach(element => {
				let recentMember = {};
				recentMember['id'] = count++;
				recentMember['username'] = element.username;
				recentMember['pictures'] = element.pictures;
				recentMember['looking_for'] = element.looking_for;
				recentMembers.push(recentMember)
			});
			setMembersData(recentMembers)
			setMembersCopyData(recentMembers)
		}
	}, [recentMembersData]);

	const changeProfile = (e, data, id) => {
		setMembersData([])
		let recentMembers = membersCopyData;
		let temp = recentMembers[id];
		temp.id = 2;
		let temp1 = recentMembers[2];
		temp1.id = id;
		recentMembers[id] = temp1;
		recentMembers[2] = temp;
		let latestMeb = recentMembers;
		setMembersData([...latestMeb])
	};

	const arrowClick = (moveType, data) => {
		if (moveType === 'add') {
			let rightShift = [];
			let arr0 = data[0], arr1 = data[1], arr2 = data[2], arr3 = data[3], arr4 = data[4],arr5=data[5],arr6=data[6],arr7=data[7],arr8=data[8],arr9=data[9];
			arr0.id = 9;
			arr1.id = 0; arr2.id = 1; arr3.id = 2; arr4.id = 3;arr5.id=4;arr6.id=5;arr7.id=6;arr8.id=7;arr9.id=9;
			rightShift.push(arr1)
			rightShift.push(arr2)
			rightShift.push(arr3)
			rightShift.push(arr4)
			rightShift.push(arr5)
			rightShift.push(arr6)
			rightShift.push(arr7)
			rightShift.push(arr8)
			rightShift.push(arr9)
			rightShift.push(arr0)
			setMembersData([...rightShift])
		} else if (moveType === 'remove') {
			let leftShift = []
			let arr0 = data[0], arr1 = data[1], arr2 = data[2], arr3 = data[3], arr4 = data[4],arr5=data[5],arr6=data[6],arr7=data[7],arr8=data[8],arr9=data[9];
			arr0.id = 1;
			arr1.id = 2; arr2.id = 3; arr3.id = 4; arr4.id = 5;arr5.id=6;arr6.id=7;arr7.id=8;arr8.id=9;arr9.id=0;
			leftShift.push(arr9)
			leftShift.push(arr0)
			leftShift.push(arr1)
			leftShift.push(arr2)
			leftShift.push(arr3)
			leftShift.push(arr4)
			leftShift.push(arr5)
			leftShift.push(arr6)
			leftShift.push(arr7)
			leftShift.push(arr8)
			setMembersData([...leftShift])
		}
	}

 

	const renderWeb = (datalist) => {
		return (
			<>
				<Grid
					container
					spacing={4}
					sx={{ marginTop: '0px' }}
				>
					<Grid item xs={12} md={12} lg={12} sm={12} display={'flex'} justifyContent={'center'}>
						<Typography variant="h6" sx={{ color: "#4120A6", textTransform: 'uppercase' }}>
							{t("recentlyJoinedMembers")}
						</Typography>
					</Grid>

					<Grid item lg={1} sm={1} xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ChevronLeftIcon className={classes.arrowIconsCarosual} onClick={() => arrowClick('remove', membersData)} /></Grid>
					{datalist.slice(0,5).map((item, index) => (
						item.id !== 2 ? (
							<Grid item key={index} lg={2} sm={2} xs={2} md={2} display={'flex'} justifyContent={'center'} alignItems="center">
								<Avatar
									onClick={(e) => changeProfile(e, item, item.id)}
									alt="Remy Sharp"
									src={`${item.pictures}`}
									sx={{ width: 56, height: 56, }}
								/></Grid>) :
							(
								<Grid item key={index} lg={2} sm={2} xs={2} md={2} display={'flex'} justifyContent={'center'} >
									<span className={classes.dot} >
										<Avatar
											onClick={(e) => changeProfile(e, item, item.id)}
											alt="Remy Sharp"
											src={item.pictures}
											sx={{ width: 80, height: 80, border: `15px solid white` }}
										/>
									</span>
								</Grid>
							)
					))}
					<Grid item lg={1} sm={1} xs={1} md={1}  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ChevronRightIcon className={classes.arrowIconsCarosual} onClick={() => arrowClick('add', membersData)} /></Grid>
					<Grid item xs={12} lg={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center', paddingTop: '0px !important' }}>
						<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
							<ListItem sx={{ textAlign: 'center' }}>
								<ListItemText primary={membersData[2]?.username} secondary={`${t("lookingFor2")}  ${t(`${membersData[2]?.looking_for}`)}`} />
							</ListItem>
						</List>
					</Grid>
				</Grid>
				<Grid
					container
					spacing={4}
					sx={{ mt: 10 }}
				>
					<Grid item xs={3.5} md={3.5} lg={3.5}>
						<div style={{ marginTop: '60px' }}>
						<a href='https://apps.apple.com/us/app/iseeju/id1639488508'><AppStore /></a>	
						</div>
						<div style={{ marginTop: '60px' }}>
							<Grid container>
								<Grid item xs={8}>
									<Typography variant="h3" sx={{ color: "#AA5EED", textAlign: 'right', }}>
										Innovative<span style={{ color: 'black' }}><br />Suchoptionen</span>
									</Typography>
									<Typography variant="h6" sx={{ textAlign: 'right', }}>
										{t("landingPageSecondary1")}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<IconButton edge="center" aria-label="delete" sx={{ p: 0 }} >
										<ProfileIcon />
									</IconButton>
								</Grid>
							</Grid>
						</div>
						<div style={{ marginTop: '60px' }}>
							<Grid container>
								<Grid item xs={8}>
									<Typography variant="h3" sx={{ color: "#AA5EED", textAlign: 'right', }}>
										Diskreter<span style={{ color: 'black' }}><br />Chat</span>
									</Typography>
									<Typography variant="h6" sx={{ textAlign: 'right', }}>
										{t("landingPageSecondary2")}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<IconButton edge="center" aria-label="delete" sx={{ p: 0 }} >
										<ChatIcon />
									</IconButton>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Grid item xs={5.5} md={5.5} lg={5.5} display='flex' alignItems={'center'} >
						<div style={{ background: `url('/assets/images/Mob_svg_new.png') no-repeat`, width: '545px', height: '700px', }}>

						</div></Grid>
					<Grid item xs={3} md={3} lg={3} sx={{ paddingLeft: '0px !important' }}>
						<div className={classes.rightContent}><a href=' https://play.google.com/store/apps/details?id=com.iseeju'><PlayStore /></a></div>
						<div className={classes.location}>
							<List sx={{ width: '100%', maxWidth: 360, }}>
								<ListItem>
									<ListItemAvatar sx={{ minWidth: '100px' }}>
										<LocationIcon />
									</ListItemAvatar>
									<ListItemText primary={
										<Typography variant="h3" sx={{ color: "#AA5EED", }}>
											Live<span style={{ color: 'black' }}><br />Funktionen</span>
										</Typography>
									} secondary={<Typography variant="h6">
										{t("landingPageSecondary3")}{`"${t("landingPageSecondary3Sub1")}"`}	{t("landingPageSecondary3Sub2")}
									</Typography>} />
								</ListItem>
							</List>
						</div>
						<div className={classes.events}>
							<List sx={{ width: '100%', maxWidth: 360, }}>
								<ListItem>
									<ListItemAvatar sx={{ minWidth: '100px' }}>
										<EventIcon />
									</ListItemAvatar>
									<ListItemText primary={
										<Typography variant="h3" sx={{ color: "#AA5EED", }}>
											Trending<span style={{ color: 'black' }}><br />Events</span>
										</Typography>
									} secondary={<Typography variant="h6">
										{t("landingPageSecondary4")}
									</Typography>} />
								</ListItem>
							</List>
						</div>
					</Grid>
				</Grid>
			</>
		)
	}

	const renderMobile = (datalist) => {
		return (
			<>
				<Grid container>
					<Grid item xs={12} md={12} lg={12} sm={12} display={'flex'} justifyContent={'center'} sx={{ mb: 5, }}>
						<Typography variant="h6" sx={{ color: "#4120A6", textTransform: 'uppercase' }}>
							{t("recentlyJoinedMembers")}
						</Typography>
					</Grid>
					<Grid item lg={1} sm={1} xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ChevronLeftIcon className={classes.arrowIconsCarosual} onClick={() => arrowClick('remove', membersData)} /></Grid>
					{datalist.slice(0,5).map((item) => (
						item.id !== 2 ? (<Grid item key={item} lg={2} sm={2} xs={2} md={2} display={'flex'} justifyContent={'center'} alignItems="center">
							<Avatar
								alt="Remy Sharp"
								src={`${item.pictures}`}
								sx={{ width: 40, height: 40, }}
							/></Grid>) :
							(
								<Grid item key={item} lg={2} sm={2} xs={2} md={2} display={'flex'} justifyContent={'center'} alignItems="center">
									<span className={classes.dotMob}>
										<Avatar
											alt="Remy Sharp"
											src={`${item.pictures}`}
											sx={{ width: 50, height: 50, border: `11px solid white` }}
										/>
									</span>
								</Grid>
							)
					))}
					<Grid item lg={1} sm={1} xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ChevronRightIcon className={classes.arrowIconsCarosual} onClick={() => arrowClick('add', membersData)} /></Grid>
					<Grid item xs={12} lg={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
							<ListItem sx={{ textAlign: 'center',pl:0 }}>
								<ListItemText primary={membersData[2]?.username} secondary={`${t("lookingFor2")}  ${t(`${membersData[2]?.looking_for}`)}`}/>
							</ListItem>
						</List>
					</Grid>
					<Grid item xs={12} className={classes.bg_mobile} sx={{}}>
					</Grid>
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
					<a href='https://apps.apple.com/us/app/iseeju/id1639488508'><AppStore /></a>	
					</Grid>
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<a href=' https://play.google.com/store/apps/details?id=com.iseeju'><PlayStore /></a>
					</Grid>
				</Grid>
				<Grid container sx={{ background: '#F3F6FF', mt: 10, pb: 7 }}>
					<Grid item xs={12} md={12} sm={12} lg={12} sx={{ mt: 7, display: 'flex', justifyContent: 'center' }}><ProfileIcon /></Grid>
					<Grid item xs={12} md={12} sm={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
						<Typography variant="h3" sx={{ color: "#AA5EED", textAlign: 'center', }}>
							Innovative<span style={{ color: 'black' }}><br />Suchoptionen</span>
						</Typography>
					</Grid>
					<Grid item xs={12} md={12} sm={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
						<Typography variant="p" className={classes.icon_text} sx={{}}>
							{t("landingPageSecondary1")}
						</Typography>
					</Grid>
					<Grid item xs={12} md={12} sm={12} lg={12} sx={{ mt: 7, display: 'flex', justifyContent: 'center' }}><ChatIcon /></Grid>
					<Grid item xs={12} md={12} sm={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
						<Typography variant="h3" sx={{ color: "#AA5EED", textAlign: 'center', }}>
							Diskreter<span style={{ color: 'black' }}>&nbsp;Chat</span>
						</Typography>
					</Grid>
					<Grid item xs={12} md={12} sm={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
						<Typography variant="p" className={classes.icon_text} sx={{}}>
							{t("landingPageSecondary2")}
						</Typography>
					</Grid>
					<Grid item xs={12} md={12} sm={12} lg={12} sx={{ mt: 7, display: 'flex', justifyContent: 'center' }}><LocationIcon /></Grid>
					<Grid item xs={12} md={12} sm={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
						<Typography variant="h3" sx={{ color: "#AA5EED", }}>
							Live<span style={{ color: 'black' }}>&nbsp;Funktionen</span>
						</Typography>
					</Grid>
					<Grid item xs={12} md={12} sm={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
						<Typography variant="p" className={classes.icon_text} sx={{}}>
							{t("landingPageSecondary3")}{`"${t("landingPageSecondary3Sub1")}"`}	{t("landingPageSecondary3Sub2")}
						</Typography>
					</Grid>
					<Grid item xs={12} md={12} sm={12} lg={12} sx={{ mt: 7, display: 'flex', justifyContent: 'center' }}><EventIcon /></Grid>
					<Grid item xs={12} md={12} sm={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
						<Typography variant="h3" sx={{ color: "#AA5EED", }}>
							Trending<span style={{ color: 'black' }}>&nbsp;Events</span>
						</Typography>
					</Grid>
					<Grid item xs={12} md={12} sm={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
						<Typography variant="p" className={classes.icon_text} sx={{}}>
							{t("landingPageSecondary4")}
						</Typography>
					</Grid>
				</Grid>
			</>
		)
	}

	const renderTablet = (datalist) => {
		return (
			<Grid container>
				<Grid item xs={12} md={12} lg={12} sm={12} display={'flex'} justifyContent={'center'} sx={{ mt: 5, mb:5,fontWeight: 'bold' }}>
					<Typography variant="h6" sx={{ color: "#4120A6", textTransform: 'uppercase' }}>
						{t("recentlyJoinedMembers")}
					</Typography>
				</Grid>
				<Grid item lg={1} sm={1} xs={1} md={1}  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ChevronLeftIcon className={classes.arrowIconsCarosual} onClick={() => arrowClick('remove', membersData)} /></Grid>
				{datalist.slice(0,5).map((item) => (
					item.id !== 2 ? (<Grid item key={item} lg={2} sm={2} xs={2} md={2}  display={'flex'} justifyContent={'center'} alignItems="center">
						<Avatar
							alt="Remy Sharp"
							src={`${item.pictures}`}
							sx={{ width: 56, height: 56, }}
						/></Grid>) :
						(
							<Grid item key={item} lg={2} sm={2} xs={2} md={2}  display={'flex'} justifyContent={'center'}  alignItems="center">
								<span className={classes.dot}>
									<Avatar
										alt="Remy Sharp"
										src={`${item.pictures}`}
										sx={{ width: 56, height: 56, border: `25px solid white` }}
									/>
								</span>
							</Grid>
						)
				))}
				<Grid item lg={1} sm={1} xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ChevronRightIcon className={classes.arrowIconsCarosual} onClick={() => arrowClick('add', membersData)} /></Grid>
				<Grid item xs={12} lg={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center', pt: 0 }}>
					<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
						<ListItem sx={{ textAlign: 'center' }}>
							<ListItemText primary={membersData[2]?.username} secondary={`${t("lookingFor2")}  ${t(`${membersData[2]?.looking_for}`)}`} />
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={2} md={2} lg={2} sm={2} display='flex' alignItems={'center'} justifyContent="center" />
				<Grid item xs={10} md={10} lg={10} sm={10} display='flex' alignItems={'center'} justifyContent="center" sx={{ mt: 5 }} >
					<div style={{ background: `url('/assets/images/Mob_svg_new.png') no-repeat`, width: '545px', height: '700px', }}>
					</div></Grid>
				<Grid item xs={12} md={12} lg={12} sm={12} display='flex' alignItems={'center'} justifyContent="center" >
				<a href='https://apps.apple.com/us/app/iseeju/id1639488508'><AppStore /></a>	<a href=' https://play.google.com/store/apps/details?id=com.iseeju'><PlayStore /></a>
				</Grid>
				<Grid item xs={6} md={6} lg={6} sm={6} display='flex' alignItems={'center'} justifyContent="center" sx={{ mt: 5 }} >
					<List sx={{ width: '100%', maxWidth: 360, }}>
						<ListItem>
							<ListItemAvatar sx={{ minWidth: '100px' }}>
								<ProfileIcon />
							</ListItemAvatar>
							<ListItemText primary={
								<Typography variant="h3" sx={{ color: "#AA5EED", }}>
									Innovative<span style={{ color: 'black' }}><br />Suchoptionen</span>
								</Typography>
							} secondary={<Typography variant="p">
								{t("landingPageSecondary1")}
							</Typography>} />
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={6} md={6} lg={6} sm={6} display='flex' alignItems={'center'} justifyContent="center" >
					<List sx={{ width: '100%', maxWidth: 360, }}>
						<ListItem>
							<ListItemAvatar sx={{ minWidth: '100px' }}>
								<ChatIcon />
							</ListItemAvatar>
							<ListItemText primary={
								<Typography variant="h3" sx={{ color: "#AA5EED", }}>
									Diskreter<span style={{ color: 'black' }}><br />Chat</span>
								</Typography>
							} secondary={<Typography variant="p">
								{t("landingPageSecondary2")}
							</Typography>} />
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={6} md={6} lg={6} sm={6} display='flex' alignItems={'center'} justifyContent="center" >
					<List sx={{ width: '100%', maxWidth: 360, }}>
						<ListItem>
							<ListItemAvatar sx={{ minWidth: '100px' }}>
								<LocationIcon />
							</ListItemAvatar>
							<ListItemText primary={
								<Typography variant="h3" sx={{ color: "#AA5EED", }}>
									Live<span style={{ color: 'black' }}><br />Funktionen</span>
								</Typography>
							} secondary={<Typography variant="p">
								{t("landingPageSecondary3")}{`"${t("landingPageSecondary3Sub1")}"`}	{t("landingPageSecondary3Sub2")}
							</Typography>} />
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={6} md={6} lg={6} sm={6} display='flex' alignItems={'center'} justifyContent="center" >
					<List sx={{ width: '100%', maxWidth: 360, }}>
						<ListItem>
							<ListItemAvatar sx={{ minWidth: '100px' }}>
								<EventIcon />
							</ListItemAvatar>
							<ListItemText primary={
								<Typography variant="h3" sx={{ color: "#AA5EED", }}>
									Trending<span style={{ color: 'black' }}><br />Events</span>
								</Typography>
							} secondary={<Typography variant="p">
								{t("landingPageSecondary4")}
							</Typography>} />
						</ListItem>
					</List>
				</Grid>
			</Grid>
		)

	}
	const renderScreens = (datalist) => {
		if (window.innerWidth < 768) {
			return renderMobile(datalist)
		} else if (window.innerWidth >= 768 && window.innerWidth <= 1280) {
			return renderTablet(datalist)
		} else if (window.innerWidth > 1280) {
			return renderWeb(datalist)
		}
	}
	return (
		<>
			{
				renderScreens(membersData)
			}



		</>
	);
};
const mapStateToProps = (state) => {
	return {
		recentMembersData: state.HappyClientsReducer.recentMembersData
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getRecentClientsService: () =>
			dispatch(happyClientsAction.getRecentClientsService()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorks);
