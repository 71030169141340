import axios from 'axios';
import { createAction } from 'redux-actions';

export const getActiveUserInitiate = createAction('GET_ACTIVE_USER_INITIATE');
export const getActiveUserSuccess = createAction('GET_ACTIVE_USER_SUCCESS');
export const getActiveUserFailure = createAction('GET_ACTIVE_USER_FAILURE');

export const newestMemberInitiate = createAction('GET_NEWESTMEMBER_INITIATE');
export const newestMemberSuccess = createAction('GET_NEWESTMEMBER_SUCCESS');
export const newestMemberFailure = createAction('GET_NEWESTMEMBER_FAILURE');

export const userByIdInitiate = createAction('USER_BY_ID_INITIATE');
export const userByIdSuccess = createAction('USER_BY_ID_SUCCESS');
export const userByIdFailure = createAction('USER_BY_ID_FAILURE');

export const FavoriteInitiate = createAction('FAVORITE_INITIATE');
export const FavoriteSuccess = createAction('FAVORITE_SUCCESS');
export const FavoriteFailure = createAction('FAVORITE_FAILURE');

export const LikeInitiate = createAction('LIKE_INITIATE');
export const LikeSuccess = createAction('LIKE_SUCCESS');
export const LikeFailure = createAction('LIKE_FAILURE');

export const inYourAreaInitiate = createAction('GET_IN_YOUR_AREA_INITIATE');
export const inYourAreaSuccess = createAction('GET_IN_YOUR_AREA_SUCCESS');
export const inYourAreaFailure = createAction('GET_IN_YOUR_AREA_FAILURE');

export const getFooterDataInitiate = createAction('GET_FOOTER_DATA_INITIATE');
export const getFooterDataSuccess = createAction('GET_FOOTER_DATA_SUCCESS');
export const getFooterDataFailure = createAction('GET_FOOTER_DATA_FAILURE');


export const validateTokenInitiate = createAction('VALIDATE_TOKEN_INITIATE');
export const validateTokenSuccess = createAction('VALIDATE_TOKEN_SUCCESS');
export const validateTokenFailure = createAction('VALIDATE_TOKEN_FAILURE');

export const acceptAGBInitiate = createAction('ACCEPT_AGB_INITIATE');
export const acceptAGBSuccess = createAction('ACCEPT_AGB_SUCCESS');
export const acceptAGBFailure = createAction('ACCEPT_AGB_FAILURE'); 

export const validateTokenService = (requestData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
    },
  };

     const bodyParameters = {
       key: "value"
     };
  return (dispatch) => {
    dispatch(validateTokenInitiate());
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL }auth_check`, bodyParameters,config)
      .then((response) => {
        // if (response.data.STATUS) {
          dispatch(validateTokenSuccess(response.data.STATUS));
        // } else {
        //   dispatch(validateTokenFailure());
        // }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(validateTokenFailure());
        }
      });
  };
};

export const getActiveUsers = (requestData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
    },
  };

  //    const bodyParameters = {
  //      key: "value"
  //    };
  return (dispatch) => {
    dispatch(getActiveUserInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }active_users_list`, config)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(getActiveUserSuccess(response.data.result));
        } else {
          dispatch(getActiveUserFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(getActiveUserFailure());
        }
      });
  };
};

export const acceptAGB = (requestData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
    },
  };
  return (dispatch) => {
    dispatch(acceptAGBInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }agb_accept`, config)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(acceptAGBSuccess(response.data));
          localStorage.setItem("agbStatus",1)
        } else {
          dispatch(acceptAGBFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(acceptAGBFailure());
        }
      });
  };
};

export const getNewestMember = (requestData,limit,offset) => {
  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
    },
  };

    //  const bodyParameters = {
    //    key: "value"
    //  };
  return (dispatch) => {
    dispatch(newestMemberInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }new_users_list?limit=${limit}&offset=${offset}`,config)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(newestMemberSuccess(response.data));
        } else {
          dispatch(newestMemberFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(newestMemberFailure());
        }
      });
  };
};



export const getUserByID = (requestData, UserID) => {
  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  //         const bodyParameters = {
  //      key: "value"
  //   };
  let data = new FormData();
  data.append('id', UserID);
  return (dispatch) => {
    dispatch(userByIdInitiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL }other_user_details`,
        data,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(userByIdSuccess(response.data.result));
        } else {
          dispatch(userByIdFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(userByIdFailure());
        }
      });
  };
};



export const favoriteOtherMember = (requestData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  //         const bodyParameters = {
  //      key: "value"
  //   };
  return (dispatch) => {
    dispatch(FavoriteInitiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL }favorite_other_member`,
        requestData,
        config
      )
      .then((response) => {
        if (response.data.STATUS===1||response.data.STATUS===2) {
          dispatch(FavoriteSuccess(response.data.time));
        } else {
          dispatch(FavoriteFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(FavoriteFailure());
        }
      });
  };
};

export const likeOtherMember = (requestData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  //         const bodyParameters = {
  //      key: "value"
  //   };
  return (dispatch) => {
    dispatch(LikeInitiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL }super_like_other_member`,
        requestData,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(LikeSuccess(response.data.message));
        } else {
          dispatch(LikeFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(LikeFailure());
        }
      });
  };
};


export const inYourArea = (requestData,limit,offset) => {
  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
    },
  };

  //    const bodyParameters = {
  //      key: "value"
  //    };
  return (dispatch) => {
    dispatch(inYourAreaInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }near_by_users?limit=${limit}&offset=${offset}`, config)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(inYourAreaSuccess(response.data));
        } else {
          dispatch(inYourAreaFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(inYourAreaFailure());
        }
      });
  };
};



export const getFooterData = (requestData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
    },
  };

  //    const bodyParameters = {
  //      key: "value"
  //    };
  return (dispatch) => {
    dispatch(getFooterDataInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }footer`, config)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(getFooterDataSuccess(response.data));
        } else {
          dispatch(inYourAreaFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(getFooterDataFailure());
        }
      });
  };
};

export const postEmailInitiate = createAction('POST_EMAIL_INITIATE');
export const postEmailSuccess = createAction('POST_EMAIL_SUCCESS');
export const postEmailFailure = createAction('POST_EMAIL_FAILURE');

export const postEmail = (requestData,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };
     const bodyParameters = {
       key: "value"
     };
  return (dispatch) => {
    dispatch(postEmailInitiate());
    axios.post(`${process.env.REACT_APP_BACKEND_URL}MailVerify`, requestData,config)
      .then((response) => {
        if(response.data.STATUS){
          dispatch(postEmailSuccess(response.data));
        }else{
          dispatch(postEmailFailure(response.data));
        }
      
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
          dispatch(postEmailFailure());
        }
      });
  };
};


export const postOTPInitiate = createAction('POST_OTP_INITIATE');
export const postOTPSuccess = createAction('POST_OTP_SUCCESS');
export const postOTPFailure = createAction('POST_OTP_FAILURE');

export const postOTP = (requestData,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  return (dispatch) => {
    dispatch(postOTPInitiate());
    axios.post(`${process.env.REACT_APP_BACKEND_URL}Web_OTP_Verify`, requestData,config)
      .then((response) => {
        if(response.data.STATUS){
          dispatch(postOTPSuccess(response.data));
          localStorage.setItem("AccVerifiedStatus",1)
        }else{
          dispatch(postOTPFailure());
        }
      
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
          dispatch(postOTPFailure());
        }
      });
  };
};

export const resendOTPInitiate = createAction('RESEND_OTP_INITIATE');
export const resendOTPSuccess = createAction('RESEND_OTP_SUCCESS');
export const resendOTPFailure = createAction('RESEND_OTP_FAILURE');

export const resendOTP = (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //    const bodyParameters = {
  //      key: "value"
  //    };
  return (dispatch) => {
    dispatch(resendOTPInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }ResentMailVerify`, config)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(resendOTPSuccess(response.data.result));
        } else {
          dispatch(resendOTPFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(resendOTPFailure());
        }
      });
  };
};
export const setCommonReducerNull = createAction('SET_COMMON_REDUCER_NULL');
export const setCommonStatusNull = ()=>{ 
  return (dispatch)=>{
    dispatch(setCommonReducerNull());
}
}