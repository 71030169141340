import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  LinearProgress,
  TextField,
  MenuItem,
  Button,
  styled,
  Badge,
  Dialog,
  DialogContent,
  DialogActions,
  Autocomplete,
  DialogTitle,
  DialogContentText,
  InputAdornment,
  IconButton,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { ReactComponent as EditIcon } from '../icons/ico_edit.svg';
import { ReactComponent as LikeIcon } from '../icons/ico like_gray.svg';
import { ReactComponent as HeartIcon } from '../icons/ico_heart_active.svg';
import { ReactComponent as HeartInActiveIcon } from '../icons/ico heart inactive.svg';
import { GENDER, INTERESTED_IN, STATUS } from '../../configs';
import useStyles from '../../styles/myProfile';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Dropzone from 'react-dropzone';
import * as userDetailsAction from '../../redux/action/userDetailsAction';
import * as commonAction from '../../redux/action/commonAction';
import * as registerAction from '../../redux/action/registerAction';
import * as chatAction from '../../redux/action/chatAction';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { ReactComponent as Level1 } from '../icons/DarkBlue Like.svg';
import { ReactComponent as Level2 } from '../icons/litebluelike.svg';
import { ReactComponent as Level3 } from '../icons/ico like green.svg';
import { ReactComponent as Level4 } from '../icons/orange_like.svg';
import { ReactComponent as Level5 } from '../icons/like_purple.svg';
import { ReactComponent as GrayLike } from '../icons/ico like_gray.svg';
import CancelIcon from '@mui/icons-material/Cancel';
import * as searchAction from '../../redux/action/searchAction';
import ConfirmationAlert from '../common/confirmationAlert';
import { ReactComponent as TimerSmallIcon } from '../icons/ico_timer_small.svg';
import OtpInput from 'react-otp-input';
import { useTranslation } from 'react-i18next';
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    // right:" 35%",
  },
}));
const BasicInfoCard = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [basicInfo, setBasicInfo] = useState(false);
  const [physicalAppearance, setPhysicalAppearance] = useState(false);
  const [about, setAbout] = useState(false);
  const history = useHistory();
  const [selectedEnrollImage, setDragAndDropFiles] = useState({
    file: '',
    imagePreviewUrl: '',
    fileSize: false,
    supportType: false
  });
  const [userGeneralData, setUserGeneralData] = useState([]);
  const [userGeneralPersonalData, setUserGeneralPersonalData] = useState({
    location: '',
    height: '',
    weight: '',
    haircolor: '',
    eyecolor: '',
    mobile: 0,
    gender: '',
    status: '',
    lookingFor: '',
    age: 0,
    about: '',
  });
  const [dob, setDOB] = React.useState(new Date(''));
  const [maxDob, setMaxDOB] = React.useState(new Date(''));
  const [minDob, setMinDOB] = React.useState(new Date(''));
  const [locationUpdate, setLocationUpdate] = useState(false);
  const [locationData, setLocationData] = useState({
    country: '',
    state: '',
    city: '',
    countryKey: '',
    stateKey: '',
    cityKey: '',
  });
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [newestMemberAlert, setNeweastMemberAlert] = useState(false);
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);
  const [alertCloseButton, setAlertCloseButton] = useState(false);
  const [alertConfirmButtonText, setAlertConfirmButtonText] = useState('');
  const [alertCloseButtonText, setAlertCloseButtonText] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [hairColors, setHairColorsData] = useState([]);
  const [eyeList, setEyeListData] = useState([]);
  const [mobileExist, setMobileExist] = useState(null)
  const [mobileUpdate, setMobileUpdate] = useState(false)
  const [mobileVerify, setMobileVerify] = useState(false)
  const [mobileUpdateData, setMobileUpdateData] = useState({ countryCode: 0, mobileNo: 0 })
  const [otp, setOtp] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const handleOTP = (value) => {
    setOtp(value);
  };
  useEffect(() => {
    var maxBirthdayDate = new Date();
    maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);
    maxBirthdayDate.setMonth(
      maxBirthdayDate.getMonth(),
      maxBirthdayDate.getDate()
    );
    setMaxDOB(maxBirthdayDate);
    var minBirthdayDate = new Date();
    minBirthdayDate.setFullYear(minBirthdayDate.getFullYear() - 100);
    minBirthdayDate.setMonth(0
    );
    minBirthdayDate.setDate(1);
    setMinDOB(minBirthdayDate)
  }, []);

  useEffect(() => {
    if (locationUpdate) {
      props.getCountryService();
    }
  }, [locationUpdate]);

  useEffect(() => {
    if (mobileUpdate) {
      props.getCountryService();
    }
  }, [mobileUpdate]);
  useEffect(() => {
    if (
      localStorage.getItem('userToken') != null &&
      window.location.pathname === '/my-profile'
    ) {
      props.userGeneralDetailsService(localStorage.getItem('userToken'));
    } else if (
      localStorage.getItem('userToken') != null &&
      window.location.pathname === '/user-profile'
    ) {
      props.getUserByID(
        localStorage.getItem('userToken'),
        localStorage.getItem('currentID')
      );
    } else if (
      localStorage.getItem('userToken') != null &&
      window.location.pathname === '/stickers'
    ) {
      props.userGeneralDetailsService(localStorage.getItem('userToken'));
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem('userToken') != null &&
      props.userGeneralDetails &&
      props.UserPersonalData &&
      window.location.pathname === '/my-profile'
    ) {
      processUserGeneralData(props.userGeneralDetails, props.UserPersonalData);
    } else if (
      localStorage.getItem('userToken') != null &&
      props.userGeneralDetails &&
      props.UserPersonalData &&
      window.location.pathname === '/stickers'
    ) {
      processUserGeneralData(props.userGeneralDetails, props.UserPersonalData);
    }
  }, [props.UserPersonalData && props.userGeneralDetails]);

  useEffect(() => {
    if (
      localStorage.getItem('userToken') != null &&
      props.otherUserDetails &&
      window.location.pathname === '/user-profile'
    ) {
      processOtherUserData(props.otherUserDetails);
    }
  }, [props.otherUserDetails]);

  const processOtherUserData = (otherUserDetails) => {
    // let profilePic = otherUserDetails?.user_gallery[0]?.pictures;
    let personalDatas = otherUserDetails[0];
    let data = {};
    if (personalDatas) {
      data['userName'] = personalDatas['username'];
      data['email'] = personalDatas['email'];
      data['gender'] = personalDatas['gender'];
      data['dob'] = personalDatas['dob'];
      data['status'] = personalDatas['martial_status'];
      data['lookingFor'] = personalDatas['looking_for'];
      data['location'] = personalDatas['location'];
      data['hairColor'] = personalDatas['hair_color'];
      data['age'] = personalDatas['age'];
      data['shortDescription'] = personalDatas['short_description'];
      data['longDescription'] = personalDatas['long_description'];
      data['eyeColor'] = personalDatas['eye_color'];
      data['height'] = personalDatas['height'];
      data['weight'] = personalDatas['weight'];
      data['mobileNo'] = personalDatas['mobile'];
      data['profilePic'] = personalDatas['pictures'];
      data['description'] = personalDatas['long_description'];
      data['level'] = personalDatas['level'];
      data['likepoints'] = personalDatas['likepoints'];
      data['fav_count'] = personalDatas['fav_count'];
      data['is_favourite'] = personalDatas['is_favourite'];
      data['userId'] = personalDatas['id'];
      data['is_online'] = personalDatas['is_online'];
      data['progress'] = personalDatas['progress'];
      data['is_liked_level'] = personalDatas['is_liked_level'];
      data['profilePicApproved'] = personalDatas['is_approved'];
      data['suprLevel1'] = personalDatas.likes_level[0].total_likes;
      data['suprLevel2'] = personalDatas.likes_level[1].total_likes;
      data['suprLevel3'] = personalDatas.likes_level[2].total_likes;
      data['suprLevel4'] = personalDatas.likes_level[3].total_likes;
      data['suprLevel5'] = personalDatas.likes_level[4].total_likes;
    }
    setDOB(data.dob);
    setUserGeneralData(data);
  };

  const processUserGeneralData = (generalData) => {
    let data = {};
    let LikeData = localStorage.getItem('userData');
    for (const property in generalData) {
      data['userName'] = generalData['username'];
      data['email'] = generalData['email'];
      data['gender'] = generalData['gender'];
      data['dob'] = generalData['dob'];
      data['status'] = generalData['martial_status'];
      data['lookingFor'] = generalData['looking_for'];
      data['location'] = generalData['location'];
      data['progress'] = generalData['progress'];
      data['hairColor'] = generalData['hair_color'];
      data['age'] = generalData['age'];
      data['shortDescription'] = generalData['short_description'];
      data['longDescription'] = generalData['long_description'];
      data['eyeColor'] = generalData['eye_color'];
      data['height'] = generalData['height'];
      data['weight'] = generalData['weight'];
      data['mobileNo'] = generalData['mobile'];
      data['profilePic'] = generalData['pictures'];
      data['profilePicApproved'] = generalData['is_approved'];
      data['description'] = generalData['long_description'];
      data['level'] = generalData['level'];
      data['likepoints'] = generalData['likepoints'];
    }
    if (props.userLikedata) {
      data['suprLevel1'] = props.userLikedata[0].total_likes;
      data['suprLevel2'] = props.userLikedata[1].total_likes;
      data['suprLevel3'] = props.userLikedata[2].total_likes;
      data['suprLevel4'] = props.userLikedata[3].total_likes;
      data['suprLevel5'] = props.userLikedata[4].total_likes;
    }

    data['fav_count'] = localStorage.getItem('userFavCount');
    setDOB(data.dob);
    setUserGeneralData(data);
  };
  const onDrop = (files) => {
    let maxSize = 5242880;
    const isFileTooLarge = files[0].size > maxSize;
    var mimeType = files[0].type;
    var fileType = mimeType.replace('image/', '');
    if (!isFileTooLarge) {
      if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
        let reader = new FileReader();
        let file = files[0];
        reader.onloadend = () => {
          setDragAndDropFiles({
            file: file,
            imagePreviewUrl: reader.result,
            fileSize: false,
            supportType: false
          });
        };
        reader.readAsDataURL(file);
      } else {
        setDragAndDropFiles({
          file: '',
          imagePreviewUrl: '',
          fileSize: false,
          supportType: true
        });
      }

    } else {
      setDragAndDropFiles({
        file: '',
        imagePreviewUrl: '',
        fileSize: true,
        supportType: false
      });
    }
  };

  const handleChange = (event) => {

    if (mobileExist && event.target.name === 'mobile' && userGeneralPersonalData.mobile !== event.target.value) {
      props.uniqueStatusNull();
      setMobileExist(null)
    }
    const key = event.target.name;
    setUserGeneralPersonalData({
      ...userGeneralPersonalData,
      [key]: event.target.value,
    });
  };

  const verifyMobile = () => {
    if (userGeneralData.mobileNo !== userGeneralPersonalData.mobile) {
      let data = new FormData();
      data.append('mobileno', userGeneralPersonalData.mobile);
      props.verifyUniqueUser(data)
    }

  }

  const handleLocationChange = (event, newValue) => {
    const key = event.target.id;
    let newKey = key.replace('-option', '');
    let finalKey = newKey.substring(0, newKey.length - 2);

    if (finalKey === 'country' || finalKey === 'state' || finalKey === 'city') {
      if (finalKey === 'country') {
        setLocationData({
          ...locationData,
          [finalKey + 'Key']: newValue.id,
          country: newValue.label,
        });
      } else if (finalKey === 'state') {
        setLocationData({
          ...locationData,
          [finalKey + 'Key']: newValue.id,
          state: newValue.label,
        });
      } else {
        setLocationData({
          ...locationData,
          [finalKey + 'Key']: newValue.id,
          city: newValue.label,
        });
      }
    } else {
      setLocationData({
        ...locationData,
        [finalKey]: newValue.label,
      });
    }
  };
  useEffect(() => {
    if (locationData.countryKey !== '') {
      props.getStateService(locationData.countryKey);
    }
  }, [locationData.countryKey]);

  useEffect(() => {
    if (locationData.stateKey !== '') {
      props.getCityService(locationData.stateKey);
    }
  }, [locationData.stateKey]);
  useEffect(() => {
    let data = {},
      arr = [];
    if (props.countryData.length > 0) {
      if (mobileUpdate) {
        props.countryData.forEach((item) => {
          data['label'] = "+" + item.country_code;
          data['id'] = item.id;
          arr.push(data);
          data = {};
        });
      }
      if (locationUpdate) {
        props.countryData.forEach((item) => {
          data['label'] = item.name;
          data['id'] = item.id;
          arr.push(data);
          data = {};
        });
      }
    }
    setCountryData(arr);
  }, [props.countryData]);

  useEffect(() => {
    let data = {},
      arr = [];
    if (props.stateData.length > 0) {
      props.stateData.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setStateData(arr);
  }, [props.stateData]);

  useEffect(() => {
    let data = {},
      arr = [];
    if (props.cityData.length > 0) {
      props.cityData.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setCityData(arr);
  }, [props.cityData]);

  const postUserData = () => {
    let newdob = new Date(dob)
    let day = newdob.getDate();
    let month = newdob.getMonth() + 1;
    let year = newdob.getFullYear();
    let mobileNo = Number(userGeneralPersonalData.mobile);
    let age =
      userGeneralPersonalData.age === 0
        ? 0
        : Number(userGeneralPersonalData.age);
    let datas = new FormData();
    datas.append('username', userGeneralData.userName);
    datas.append('dob', `${year}-${month < 9 ? '0' + month : month}-${day < 9 ? '0' + day : day}`);
    datas.append(
      'gender',
      userGeneralPersonalData.gender === ''
        ? userGeneralData.gender
        : userGeneralPersonalData.gender
    );
    datas.append('country_code', locationData.countryKey);
    datas.append('state_code', locationData.stateKey);
    datas.append('city_code', locationData.cityKey);
    datas.append(
      'looking_for',
      userGeneralPersonalData.lookingFor === ''
        ? userGeneralData.lookingFor
        : userGeneralPersonalData.lookingFor
    );
    datas.append('age', age);
    datas.append('short_description', '');
    if (userGeneralPersonalData.about.length > 0 ) {
      userGeneralPersonalData.about =
          userGeneralPersonalData.about.replace(/&/g, "&amp;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;")
              .replace(/"/g, "&quot;")
              .replace(/'/g, "&#39;")
    }
    datas.append('long_description', userGeneralPersonalData.about);
    datas.append('civil', '');
    datas.append('body_type', '');
    datas.append(
      'hair_color',
      userGeneralPersonalData.haircolor === ''
        ? userGeneralData.hairColor
        : userGeneralPersonalData.haircolor
    );
    datas.append(
      'martial_status',
      userGeneralPersonalData.status === ''
        ? userGeneralData.status
        : userGeneralPersonalData.status
    );
    datas.append(
      'eye_color',
      userGeneralPersonalData.eyecolor === ''
        ? ''
        : userGeneralPersonalData.eyecolor
    );
    datas.append(
      'weight',
      userGeneralPersonalData.weight === ''
        ? userGeneralData.weight
        : userGeneralPersonalData.weight
    );
    datas.append(
      'height',
      userGeneralPersonalData.height === ''
        ? userGeneralData.height
        : userGeneralPersonalData.height
    );
    datas.append('mobile', mobileNo);
    datas.append('profile_pic', selectedEnrollImage.file);
    datas.append('reference', '');
    props.updateUserDetails(localStorage.getItem('userToken'), datas);
  };

  useEffect(() => {
    if (props.updateStatus === 1) {
      if (window.location.pathname === '/my-profile' || window.location.pathname === '/stickers') {
        props.userGeneralDetailsService(localStorage.getItem('userToken'));
      }


      if (basicInfo) {
        setBasicInfo(false);
      }

      if (physicalAppearance) {
        setPhysicalAppearance(false);
      }

      if (about) {
        setAbout(false);
      }

      if (selectedEnrollImage.file !== '') {
        setDragAndDropFiles({
          file: '',
          imagePreviewUrl: '',
        });
      }
      if (locationUpdate) {
        setLocationUpdate(false);
      }
    }
  }, [props.updateStatus]);

  const handleDOB = (newValue) => {
    if (newValue !== null) {
      setDOB(newValue);
    }
  };
  const handleFavorite = (is_favorite, id) => {
    let data = new FormData();
    data.append('member_id', id);
    data.append('action', is_favorite === 1 ? 0 : 1);
    props.addorRemoveFavorite(data);
  };
  useEffect(() => {
    if (
      localStorage.getItem('userToken') != null &&
      window.location.pathname === '/user-profile'
    ) {
      props.getUserByID(
        localStorage.getItem('userToken'),
        localStorage.getItem('currentID')
      );
    }
  }, [props.favoriteStatus]);

  const handleLike = (item) => {
    let data = new FormData();
    data.append('member_id', item.userId);
    data.append('action', 1);
    data.append(
      'level',
      item.is_liked_level <= 4 ? item.is_liked_level + 1 : ''
    );
    if (item.is_liked_level <= 4) {
      props.likeorDislike(data);
    }
  };

  const getIcon = (item) => {
    let icon;
    if (item.is_liked_level === 0) {
      icon = (
        <GrayLike
          onClick={() => handleLike(item)}
          className={classes.likeIcon}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 9,
            height: '30px',
            width: '30px',
          }}
        />
      );
    } else if (item.is_liked_level === 1) {
      icon = (
        <Level1
          onClick={() => handleLike(item)}
          className={classes.likeIcon}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 9,
            height: '30px',
            width: '30px',
          }}
        />
      );
    } else if (item.is_liked_level === 2) {
      icon = (
        <Level2
          onClick={() => handleLike(item)}
          className={classes.likeIcon}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 9,
            height: '30px',
            width: '30px',
          }}
        />
      );
    } else if (item.is_liked_level === 3) {
      icon = (
        <Level3
          onClick={() => handleLike(item)}
          className={classes.likeIcon}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 9,
            height: '30px',
            width: '30px',
          }}
        />
      );
    } else if (item.is_liked_level === 4) {
      icon = (
        <Level4
          onClick={() => handleLike(item)}
          className={classes.likeIcon}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 9,
            height: '30px',
            width: '30px',
          }}
        />
      );
    } else if (item.is_liked_level === 5) {
      icon = (
        <Level5
          title="Level Reached"
          className={classes.likeIcon}
          style={{
            position: 'absolute',
            zIndex: 9,
            height: '30px',
            width: '30px',
          }}
        />
      );
    }
    return icon;
  };

  useEffect(() => {
    if (props.likeStatus && window.location.pathname === '/user-profile') {
      props.getUserByID(
        localStorage.getItem('userToken'),
        localStorage.getItem('currentID')
      );
    } else if (props.likeStatus === false) {

      props.setCommonStatusNull();
      props.getUserByID(
        localStorage.getItem('userToken'),
        localStorage.getItem('currentID')
      );
      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(t("dontHaveLikePoint"));
      setAlertText(t("earnAndTryLater"));
    }
  }, [props.likeStatus]);

  const sendMessage = (e) => {
    e.stopPropagation();
    let data = new FormData();
    localStorage.setItem('currentChatId', localStorage.getItem('currentID'));
    data.append('to_user_id', localStorage.getItem('currentID'));
    props.InitiateNewChat(data, localStorage.getItem('userToken'));
  };

  useEffect(() => {
    if (props.newChatConnectionStatus) {
      history.push('/chat');
    }
  }, [props.newChatConnectionStatus]);

  const handleClose = () => {
    setLocationData({ country: '', city: '', state: '' });
    setLocationUpdate(false);
    setMobileUpdate(false)
    setMobileVerify(false)
  };
  const CloseAlert = () => {
    setNeweastMemberAlert(false);
    setAlertConfirmButton(false);
    setAlertCloseButton(false);
    setAlertConfirmButtonText('');
    setAlertCloseButtonText('');
    setAlertTitle('');
    setAlertText('');
  };
  const confirmFunc = () => { };

  useEffect(() => {
    props.getHairColorService();
    props.getEyeListService();
  }, []);
  useEffect(() => {
    let data = {},
      arr = [];
    if (props.hairColors.length > 0) {
      props.hairColors.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setHairColorsData(arr);
  }, [props.hairColors]);

  useEffect(() => {
    let data = {},
      arr = [];
    if (props.eyeList.length > 0) {
      props.eyeList.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setEyeListData(arr);
  }, [props.eyeList]);

  useEffect(() => {
    if (props.isMobileExist === true) {
      setMobileExist(true)
    } else if (props.isMobileExist === false) {
      setMobileExist(false)
    }
  }, [props.isMobileExist])

  const handleOnChange = (e, value) => {
    const key = e.target.id;
    const mobileName = e.target.name;
    let newKey = key.replace('-option', '');
    let finalKey = newKey.substring(0, newKey.length - 2);
    if (finalKey === 'countryCode') {
      setMobileUpdateData({
        ...mobileUpdateData,
        ['countryCode']: value.id,
        country: value.label,
      });
    }

    if (mobileName === 'mobileNo') {
      setMobileUpdateData({
        ...mobileUpdateData,
        ['mobileNo']: e.target.value,
      });
    }
    // setMobileUpdateData(...mobileUpdateData,)
    // console.log(e.target.name);
    // console.log(e.target.id?'>>>>>'+e.target.id:`>>>>>><<<<<<<${e.target.name}`);
  }

  const sendOtptoVerifyMobile = () => {
    let data = new FormData();
    data.append('country_code', mobileUpdateData.countryCode)
    data.append('mobile_no', mobileUpdateData.mobileNo)
    props.updateMobileNo(localStorage.getItem('userToken'), data)
  }

  useEffect(() => {
    if (props.mobileOtpSendStatus?.STATUS) {
      setMobileUpdateData({ countryCode: 0, mobileNo: 0 })
      setMobileUpdate(false)
      setMobileVerify(true)
    } else if (props.mobileOtpSendStatus?.STATUS === false) {
      setMobileUpdateData({ countryCode: 0, mobileNo: 0 })
      setMobileUpdate(false)
      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(t("message"));
      setAlertText(props.mobileOtpSendStatus?.message);
    }
  }, [props.mobileOtpSendStatus])

  const verifyOtpFunc = () => {
    let data = new FormData();
    data.append('otp', otp)
    props.verifyMobileOtp(localStorage.getItem('userToken'), data)
  }

  useEffect(() => {
    if (props.mobileOtpVerifyStatus?.STATUS) {
      setMobileVerify(false)

      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(t("message"));
      setAlertText(props.mobileOtpVerifyStatus?.message);
      props.userGeneralDetailsService(localStorage.getItem('userToken'));
      setBasicInfo(false);
    } else if (props.mobileOtpVerifyStatus?.STATUS === false) {
      setMobileVerify(false)
      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(t("message"));
      setAlertText(props.mobileOtpVerifyStatus?.message);
      setBasicInfo(false);
    }
  }, [props.mobileOtpVerifyStatus])

  const getSplitAddress = (address) => {
    const words = address.split(',');
    if (words.length === 3) {
      return (<Typography
        variant="body2"
        component="p"
        className={classes.text}
      >
        {`${words[0]},${words[1]}`}<br />{words[2]}
      </Typography>
      )
    } else if (words.length === 2) {
      return (<Typography
        variant="body2"
        component="p"
        className={classes.text}
      >
        {`${words[0]},${words[1]}`}
      </Typography>
      )
    } else {
      return (<Typography
        variant="body2"
        component="p"
        className={classes.text}
      >
        {address}
      </Typography>
      )
    }
  }
  return (
    <>
      <ConfirmationAlert
        alert={newestMemberAlert}
        confirmButton={alertConfirmButton}
        closeButton={alertCloseButton}
        confirmButtonText={alertConfirmButtonText}
        closeButtonText={alertCloseButtonText}
        alertTitle={alertTitle}
        alertText={alertText}
        closeFunc={CloseAlert}
        confirmFunc={confirmFunc}
        buttonDisabled={buttonDisabled}
      />
      <Card sx={{ borderRadius: '10px' }}>
        <CardContent>
          <Grid container display='flex' justifyContent="space-between">
            <Grid item xs={2} sx={{ position: 'relative' }}>
              {props.myProfile ? (
                <LikeIcon
                  style={{
                    position: 'absolute',
                    zIndex: 9,
                    height: '30px',
                    width: '30px',
                  }}
                />
              ) : (
                getIcon(userGeneralData)
              )}
              <Typography
                variant="body1"
                component="span"
                sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
                className={classes.box1}
              >
                {userGeneralData.suprLevel5}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
                className={classes.box2}
              >
                {userGeneralData.suprLevel4}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
                className={classes.box3}
              >
                {userGeneralData.suprLevel3}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
                className={classes.box4}
              >
                {userGeneralData.suprLevel2}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
                className={classes.box5}
              >
                {userGeneralData.suprLevel1}
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              sx={{ display: 'grid', justifyContent: 'center' }}
            >
              <Grid container xs={12} >
                <Grid item xs={12} sx={props.myProfile ? { marginRight: "25px" } : { display: 'flex', justifyContent: 'center' }}>     {props.myProfile ? (
                  <Dropzone
                    //  maxSize={5242880}
                    multiple={false}
                    onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <Grid container xs={12} display='flex' justifyContent='center'>
                        {selectedEnrollImage.imagePreviewUrl !== '' ? (
                          <section className={classes.profileUpload}>
                            <input {...getInputProps()} />
                            {/* <div
                        {...getRootProps()}
                        className={classes.profileUpload}
                      > */}
                            {/* {files.map((file, index) => {
                                  return ( */}
                            <Avatar
                              alt="Remy Sharp"
                              src={selectedEnrollImage.imagePreviewUrl}
                              sx={{
                                width: 85,
                                height: 85,
                                border: '2px solid #F7B573',
                                margin: '0 auto',
                              }}
                            />

                            {/* ); */}
                            {/* })} */}
                            {/* </div> */}
                          </section>
                        ) : (
                          <section className={classes.profileUpload}>
                            <input {...getInputProps()} />
                            <Avatar
                              alt="Remy Sharp"
                              src={`${userGeneralData.profilePic}`}
                              sx={{
                                width: 85,
                                height: 85,
                                border: '2px solid #F7B573',
                                // margin: '0 auto',
                              }}
                            />
                            {userGeneralData.profilePicApproved === 0 ? <TimerSmallIcon className={classes.TimerIcon} title='Waiting for approval' /> : ''}
                            <EditIcon
                              {...getRootProps()}
                              className={classes.EditImage}
                              title='Edit'
                            />
                          </section>
                        )}
                      </Grid>
                    )}
                  </Dropzone>
                ) : userGeneralData.is_online ? (
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={`${userGeneralData.profilePic}`}
                      sx={{
                        width: 85,
                        height: 85,
                        border: '2px solid #F7B573',
                        margin: '0 auto',
                      }}
                    />
                  </StyledBadge>
                ) : (
                  <Avatar
                    alt="Remy Sharp"
                    src={`${userGeneralData.profilePic}`}
                    sx={{
                      width: 85,
                      height: 85,
                      border: '2px solid #F7B573',
                      margin: '0 auto',
                    }}
                  />
                )}</Grid>
                {selectedEnrollImage.fileSize === true ? (
                  <Grid sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Typography sx={{ color: 'red' }}>
                      File Size is too large.
                    </Typography>
                  </Grid>
                ) : (
                  ''
                )}
                {selectedEnrollImage.supportType === true ? (
                  <Grid sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Typography sx={{ color: 'red' }}>
                      JPG/PNG/JPEG only supported
                    </Typography>
                  </Grid>
                ) : (
                  ''
                )}
                {selectedEnrollImage.file !== '' ? (
                  <Grid sx={{ display: 'flex' }}>
                    <Button
                      variant="contained"
                      className={classes.updateButton}
                      sx={{
                        marginTop: '12px',
                        marginBottom: '10px',
                        marginRight: '10px',
                      }}
                      onClick={() => postUserData()}
                    >
                      {t("update")}
                    </Button>
                    <Button
                      variant="outlined"
                      className={classes.updateButton}
                      sx={{
                        marginTop: '12px',
                        marginBottom: '10px',
                      }}
                      onClick={() =>
                        setDragAndDropFiles({
                          file: '',
                          imagePreviewUrl: '',
                        })
                      }
                    >
                      {t("cancel")}
                    </Button>
                  </Grid>
                ) : null}
                <Grid item xs={12} display='flex' justifyContent='center' sx={{mt:props.myProfile?1:0}}>
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ color: '#272727', margin: '6px 0px' }}
                  >
                    {userGeneralData.userName}
                  </Typography>
                  {/* {myProfile ? <EditIcon /> : null} */}

                </Grid>
                <Grid item xs={12} display='flex' justifyContent='center'>   <LinearProgress
                  value={userGeneralData.progress}
                  variant="determinate"
                  thickness={14}
                  color="success"
                  classes={{
                    determinate: classes.profileProgress,
                    root: classes.progressBar,
                  }}
                /></Grid>
                <Grid item xs={12} display='flex' justifyContent='center'>
                  {props.myProfile ? (
                    ''
                  ) : (
                    <Button
                      onClick={(e) => sendMessage(e)}
                      variant="contained"
                      className={classes.viewProfile}
                      sx={{
                        marginTop: '12px',
                        marginBottom: '10px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'flex',
                      }}
                      startIcon={<ChatIcon />}
                    >
                      {t("sendMessage")}
                    </Button>
                  )}</Grid>
              </Grid>





            </Grid>
            <Grid item xs={2} sx={{ position: 'relative' }}>
              {props.myProfile ? (
                <HeartIcon
                  style={{
                    position: 'absolute',
                    zIndex: 9,
                    height: '30px',
                    width: '30px',
                    right: 0,
                  }}
                />
              ) : userGeneralData.is_favourite && !props.myProfile ? (
                <HeartIcon
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    zIndex: 9,
                    height: '30px',
                    width: '30px',
                    right: 0,
                  }}
                  onClick={() =>
                    handleFavorite(
                      userGeneralData.is_favourite,
                      userGeneralData.userId
                    )
                  }
                />
              ) : (
                <HeartInActiveIcon
                  style={{
                    position: 'absolute',
                    zIndex: 9,
                    height: '30px',
                    width: '30px',
                    right: 0,
                  }}
                  onClick={() =>
                    handleFavorite(
                      userGeneralData.is_favourite,
                      userGeneralData.userId
                    )
                  }
                />
              )}

              <Typography
                variant="body1"
                component="span"
                sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
                className={classes.box6}
              >
                {userGeneralData.fav_count}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            sx={{ margin: '40px 0px 20px 0px' }}
          >
            <Typography variant="body1" component="h6">
              {t("basicInformation")}
            </Typography>
            {props.myProfile ? (
              <EditIcon
                onClick={() => setBasicInfo(true)}
                style={{ cursor: 'pointer' }}
              />
            ) : null}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="body2"
                component="p"
                className={classes.headings}
              >
                {t("location")}
              </Typography>
              {basicInfo ? (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.location != null
                    ? getSplitAddress(userGeneralData.location)
                    : ''}{' '}
                  <EditIcon
                    onClick={() => setLocationUpdate(true)}
                    style={{ cursor: 'pointer' }}
                  />
                </Typography>
              ) : (


                userGeneralData.location != null
                  ? getSplitAddress(userGeneralData.location)
                  : <Typography
                    variant="body2"
                    component="p"
                    className={classes.text}
                  >
                    {t("addressNotFound")}
                  </Typography>


              )}

              <Typography
                variant="body2"
                component="p"
                className={classes.headings}
              >
                {t("gender")}
              </Typography>
              {basicInfo ? (
                <TextField
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  select
                  name="gender"
                  value={
                    userGeneralPersonalData.gender === ''
                      ? userGeneralData.gender
                      : userGeneralPersonalData.gender
                  }
                  // value={values.gender}
                  // onChange={handleChange}
                  // helperText={
                  // 	errors.gender &&
                  // 	touched.gender && (
                  // 		<span className={classes.errorText}>{errors.gender}</span>
                  // 	)
                  // }
                  InputProps={{
                    classes: {
                      input: classes.inputFields,
                      root: classes.inputRoot,
                    },
                    disableUnderline: true,
                  }}
                  fullWidth
                >
                  {GENDER.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {t(`${userGeneralData.gender}`)}
                </Typography>
              )}
              <Typography
                variant="body2"
                component="p"
                className={classes.headings}
              >
                {t("status")}
              </Typography>
              {basicInfo ? (
                <TextField
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  select
                  name="status"
                  defaultValue={userGeneralData.status}
                  InputProps={{
                    classes: {
                      input: classes.inputFields,
                      root: classes.inputRoot,
                    },
                    disableUnderline: true,
                  }}
                  fullWidth
                >
                  {STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.status != null ? t(`${userGeneralData.status}`) : ''}
                </Typography>
              )}
              {props.myProfile ? (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.headings}
                >
                  {t("basicInfolookingFor")}
                </Typography>
              ) : (
                ''
              )}
              {basicInfo ? (
                <TextField
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  select
                  name="lookingFor"
                  defaultValue={userGeneralData.lookingFor}
                  // value={values.lookingFor}
                  // onChange={handleChange}
                  // helperText={
                  // 	errors.lookingFor &&
                  // 	touched.lookingFor && (
                  // 		<span className={classes.errorText}>{errors.lookingFor}</span>
                  // 	)
                  // }
                  InputProps={{
                    classes: {
                      input: classes.inputFields,
                      root: classes.inputSelectRoot,
                    },
                    disableUnderline: true,
                  }}
                  fullWidth
                >
                  {INTERESTED_IN.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : props.myProfile ? (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.lookingFor != null
                    ? t(`${userGeneralData.lookingFor}`)
                    : ''}
                </Typography>
              ) : (
                ''
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              {props.myProfile ? (
                  ""
              ) : (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.headings}
                >
                  {t("basicInfolookingFor")}
                </Typography>
              )}
              {basicInfo ? (
                <>
                {/*<TextField
                    fullWidth
                    onChange={(event) => handleChange(event)}
                    variant="outlined"
                    name="mobile"
                    defaultValue={userGeneralData.mobileNo}
                    onBlur={() => verifyMobile()}
                    placeholder="+64 2211 778"
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            // onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            <EditIcon onClick={() => setMobileUpdate(true)}
                              style={{ cursor: 'pointer' }} />
                          </IconButton>
                        </InputAdornment>,
                      classes: {
                        input: classes.inputFields,
                        root: classes.inputRoot,
                      },
                      disableUnderline: true,
                    }}
                  />*/}

                  {mobileExist ? <Typography
                    variant="body2"
                    component="p"
                    sx={{ color: 'error.main' }}
                  >
                    {t("mobNumAlreadyExist")}
                  </Typography> : ''}
                </>
              ) : props.myProfile ? (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.mobileNo !== ''
                    ? userGeneralData.mobileNo
                    : ''}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.lookingFor != null
                    ? t(`${userGeneralData.lookingFor}`)
                    : ''}
                </Typography>
              )}

              <Typography
                variant="body2"
                component="p"
                className={classes.headings}
              >
                {t("birthday")}
              </Typography>
              {basicInfo ? (
                <>
                  {/* <TextField
                onChange={(event) => handleChange(event)}
                variant="outlined"
                name="dob"
                placeholder="Berlin, Germany"
                InputProps={{
                  classes: {
                    input: classes.inputFields,
                    root: classes.inputRoot,
                  },
                  disableUnderline: true,
                }}
              /> */}
                  <DesktopDatePicker
                    fullWidth
                    name="dob"
                    onChange={handleDOB}
                    //label=" Birthday:"
                    value={dob}
                    maxDate={maxDob}
                    minDate={minDob}
                    defaultValue={dob}
                    InputProps={{
                      classes: {
                        input: classes.inputFields,
                        root: classes.inputRoot,
                      },
                      disableUnderline: true,
                    }}
                    sx={{ paddingTop: '16.5px' }}
                    renderInput={(params) => (
                      <TextField sx={{ pt: 0.2 }} name="dob" {...params} fullWidth />
                    )}
                  />
                </>
              ) : (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.dob !== '' ? userGeneralData.dob : ''}
                </Typography>
              )}
              <Typography
                variant="body2"
                component="p"
                className={classes.headings}
              >
                {t("age")}
              </Typography>

              {basicInfo ? (
                <TextField
                  disabled
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  name="age"
                  defaultValue={userGeneralData.age}
                  placeholder="Please select your age"
                  InputProps={{
                    classes: {
                      input: classes.inputFields,
                      root: classes.inputRoot,
                    },
                    disableUnderline: true,
                  }}
                  fullWidth
                >
                  {/* {STATUS.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))} */}
                </TextField>
              ) : (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.age !== '' ? userGeneralData.age : ''}
                </Typography>
              )}

              {props.myProfile ? (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.headings}
                >
                  Email
                </Typography>
              ) : (
                ''
              )}
              {basicInfo ? (
                <TextField
                  fullWidth
                  disabled={true}
                  variant="outlined"
                  name="search"
                  placeholder={userGeneralData.email}
                  InputProps={{
                    classes: {
                      input: classes.inputFields,
                      root: classes.inputRoot,
                    },
                    disableUnderline: true,
                  }}
                />
              ) : props.myProfile ? (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.email !== '' ? userGeneralData.email : ''}
                </Typography>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          {basicInfo ? (
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                className={classes.updateButton}
                sx={{
                  marginTop: '12px',
                  marginBottom: '10px',
                  marginRight: '10px',
                }}
                onClick={() => postUserData()}
              >
                {t("update")}
              </Button>
              <Button
                variant="outlined"
                className={classes.updateButton}
                sx={{
                  marginTop: '12px',
                  marginBottom: '10px',
                }}
                onClick={() => setBasicInfo(false)}
              >
                {t("cancel")}
              </Button>
            </Grid>
          ) : null}
          <Grid
            container
            justifyContent="space-between"
            sx={{ margin: '20px 0px' }}
          >
            <Typography variant="body1" component="h6">
              {t("physicalApperance")}
            </Typography>
            {props.myProfile ? (
              <EditIcon
                onClick={() => setPhysicalAppearance(true)}
                style={{ cursor: 'pointer' }}
              />
            ) : null}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="body2"
                component="p"
                className={classes.headings}
              >
                {t("height")}:
              </Typography>
              {physicalAppearance ? (
                <TextField
                  fullWidth
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  name="height"
                  placeholder=""
                  InputProps={{
                    classes: {
                      input: classes.inputFields,
                      root: classes.inputRoot,
                    },
                    disableUnderline: true,
                  }}
                />
              ) : (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.height !== '' ? Math.round(userGeneralData.height) : ''}
                </Typography>
              )}
              <Typography
                variant="body2"
                component="p"
                className={classes.headings}
              >
                {t("weight")}:
              </Typography>
              {physicalAppearance ? (
                <TextField
                  fullWidth
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  name="weight"
                  placeholder=""
                  InputProps={{
                    classes: {
                      input: classes.inputFields,
                      root: classes.inputRoot,
                    },
                    disableUnderline: true,
                  }}
                />
              ) : (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.weight !== '' ? Math.round(userGeneralData.weight) : ''}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body2"
                component="p"
                className={classes.headings}
              >
                {t("basicInfoHairColor")}:
              </Typography>
              {physicalAppearance ? (
                <TextField
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  select
                  name="haircolor"
                  defaultValue={userGeneralData.hairColor}
                  InputProps={{
                    classes: {
                      input: classes.inputFields,
                      root: classes.inputSelectRoot,
                    },
                    disableUnderline: true,
                  }}
                  fullWidth
                >
                  {hairColors.map((option) => (
                    <MenuItem key={option.id} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.hairColor !== ''
                    ? userGeneralData.hairColor
                    : ''}
                </Typography>
              )}
              <Typography
                variant="body2"
                component="p"
                className={classes.headings}
              >
                {t("basicInfoEyeColor")}:
              </Typography>
              {physicalAppearance ? (
                <TextField
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  select
                  name="eyecolor"
                  defaultValue={userGeneralData.eyeColor}
                  InputProps={{
                    classes: {
                      input: classes.inputFields,
                      root: classes.inputSelectRoot,
                    },
                    disableUnderline: true,
                  }}
                  fullWidth
                >
                  {eyeList.map((option) => (
                    <MenuItem key={option.id} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.eyeColor !== ''
                    ? userGeneralData.eyeColor
                    : ''}
                </Typography>
              )}
            </Grid>
          </Grid>
          {physicalAppearance ? (
            <Grid container justifyContent="flex-end">
              <Button
                onClick={() => postUserData()}
                variant="contained"
                className={classes.updateButton}
                sx={{
                  marginTop: '12px',
                  marginBottom: '10px',
                  marginRight: '10px',
                }}
              >
                {t("update")}
              </Button>
              <Button
                variant="outlined"
                className={classes.updateButton}
                sx={{
                  marginTop: '12px',
                  marginBottom: '10px',
                }}
                onClick={() => setPhysicalAppearance(false)}
              >
                {t("cancel")}
              </Button>
            </Grid>
          ) : null}
          <Grid
            container
            justifyContent="space-between"
            sx={{ margin: '20px 0px' }}
          >
            <Typography variant="body1" component="h6">
              {t("aboutMe")}
            </Typography>
            {props.myProfile ? (
              <EditIcon
                onClick={() => setAbout(true)}
                style={{ cursor: 'pointer' }}
              />
            ) : null}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={12}>
              {about ? (
                <TextField
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  multiline
                  rows={3}
                  name="about"
                  placeholder=""
                  InputProps={{
                    classes: {
                      input: classes.inputFields,
                      root: classes.inputRoot,
                    },
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              ) : (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text}
                >
                  {userGeneralData.longDescription}
                </Typography>
              )}
            </Grid>
          </Grid>
          {about ? (
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                className={classes.updateButton}
                sx={{
                  marginTop: '12px',
                  marginBottom: '10px',
                  marginRight: '10px',
                }}
                onClick={() => postUserData()}
              >
                {t("update")}
              </Button>
              <Button
                variant="outlined"
                className={classes.updateButton}
                sx={{
                  marginTop: '12px',
                  marginBottom: '10px',
                }}
                onClick={() => setAbout(false)}
              >
                {t("cancel")}
              </Button>
            </Grid>
          ) : null}
          <Grid
            container
            justifyContent="space-between"
            sx={{ margin: '20px 0px' }}
          >
            <Typography variant="body1" component="h6">
              {t("currentLevel")}:{' '}
              <span className={classes.currentLevel}>
                {userGeneralData.level}
              </span>
            </Typography>
            {props.myProfile ? (
              <Typography variant="body1" component="h6">
                {t("likePoints")}:{' '}
                <span className={classes.likePoints}>
                  {userGeneralData.likepoints}
                </span>
              </Typography>
            ) : (
              ''
            )}
          </Grid>
        </CardContent>
      </Card>
      <Dialog open={locationUpdate} onClose={handleClose}>
        <DialogContent
          sx={{
            minWidth: '30rem',
            minHeight: '15rem',
            width: 'auto',
            height: 'auto',
          }}
        >
          <CancelIcon className={classes.closeIcon} onClick={handleClose} />
          <Typography variant="body1" component="p">
            {t("changeLocation")}
          </Typography>
          <Grid container justifyContent="center">
            <Grid
              sx={{ mt: 1 }}
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {/* <Typography> {t("country")} </Typography> */}
              <Autocomplete
                value={locationData.country}
                disablePortal
                onChange={(event, newValue) =>
                  handleLocationChange(event, newValue)
                }
                id="country"
                options={countryData}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label={t("country")} />
                )}
              />
            </Grid>

            <Grid
              item
              sx={{ mt: 1 }}
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {stateData.length > 0 ? (
                <>
                  {' '}
                  {/* <Typography>{t("state")}</Typography> */}
                  <Autocomplete
                    value={locationData.state}
                    disablePortal
                    onChange={(event, newValue) =>
                      handleLocationChange(event, newValue)
                    }
                    id="state"
                    options={stateData}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label={t("state")} />
                    )}
                  />
                </>
              ) : (
                ''
              )}
            </Grid>

            <Grid
              sx={{ mt: 1 }}
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {cityData.length > 0 ? (
                <>
                  {' '}
                  {/* <Typography>{t("city")}</Typography> */}
                  <Autocomplete
                    value={locationData.city}
                    disablePortal
                    onChange={(event, newValue) =>
                      handleLocationChange(event, newValue)
                    }
                    id="city"
                    options={cityData}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label={t("city")} />
                    )}
                  />
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => postUserData()}
              sx={{ borderRadius: '5px' }}
            >
              {t("update")}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => handleClose()}
              sx={{ borderRadius: '5px' }}
            >
              {t("cancel")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={mobileUpdate}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontWeight: 'bold' }}> {t("enterYourMobile")} </DialogTitle>
        <DialogContent >
          <DialogContentText sx={{ display: 'flex', justifyContent: 'left' }} id="alert-dialog-slide-description">
            {t("weWillsendAText")} <br />{t("verificationCode")}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Autocomplete
            value={mobileUpdateData.country}
            disablePortal
            onChange={(event, newValue) =>
              handleOnChange(event, newValue)
            }
            id="countryCode"
            options={countryData}
            sx={{ width: 100, borderRadius: '15px !important' }}
            renderInput={(params) => (
              <TextField {...params} placeholder="" />
            )}
          />
          <TextField
            onChange={(event, value) => handleOnChange(event, value)}
            variant="outlined"
            name="mobileNo"
            defaultValue={userGeneralData.mobileNo}
            // onBlur={() => verifyMobile()}
            placeholder=""
            InputProps={{
              // classes: {
              //   input: classes.inputFields,
              //   root: classes.inputRoot,
              // },
              disableUnderline: true,
            }}
          />
        </DialogActions>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => sendOtptoVerifyMobile()} variant='contained' color='primary' sx={{ borderRadius: '5px' }}>{t("timerConfirmButton")}</Button>
          <Button onClick={() => handleClose()} variant='contained' color='secondary' sx={{ borderRadius: '5px' }}>{t("cancel")}</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={mobileVerify}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description2"
      >
        <DialogTitle sx={{ fontWeight: 'bold' }}> {t("verifyYourPhone")} </DialogTitle>
        <DialogContent >
          <DialogContentText sx={{ display: 'flex', justifyContent: 'left' }} id="alert-dialog-slide-description2">
            {t("enterTheCodeSendToMobile")} <br />{t("number")}{mobileUpdateData.mobileNo !== 0 ? mobileUpdateData.mobileNo.slice(-4) : ''}.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <OtpInput
            value={otp}
            onChange={(value) => handleOTP(value)}
            numInputs={6}
            containerStyle={classes.otpContainer}
            inputStyle={classes.inputContainer}
            // sx={ { width: '1px !important' }}
            isInputNum={true}
            hasErrored={true}
          />
        </DialogActions>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => verifyOtpFunc()} variant='contained' color='primary' sx={{ borderRadius: '5px' }}>{t("timerConfirmButton")}</Button>
          <Button onClick={() => handleClose()} variant='contained' color='secondary' sx={{ borderRadius: '5px' }}>{t("cancel")}</Button>
        </DialogActions>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant='p'>Didn't receive a code?&nbsp;<a href='#' onClick={() => sendOtptoVerifyMobile()} style={{ color: 'primary' }}>{t("sendAgain")}</a></Typography>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    UserPersonalData: state.UserDetailsReducer.userPersonalData,
    userGeneralDetails: state.UserDetailsReducer.userGeneralData,
    otherUserDetails: state.CommonReducer.userDataById,
    updateStatus: state.UserDetailsReducer.apiStatus,
    favoriteStatus: state.CommonReducer.favoriteStatus,
    likeStatus: state.CommonReducer.likeStatus,
    newChatConnectionStatus: state.ChatReducer.newChatConnectionStatus,
    countryData: state.SearchReducer.countryData,
    stateData: state.SearchReducer.stateData,
    cityData: state.SearchReducer.cityData,
    userLikedata: state.UserDetailsReducer.userLikedata,
    hairColors: state.SearchReducer.hairColors,
    eyeList: state.SearchReducer.eyeList,
    isMobileExist: state.RegisterReducer.isMobileExist,
    mobileOtpSendStatus: state.UserDetailsReducer.mobileOtpSendStatus,
    mobileOtpVerifyStatus: state.UserDetailsReducer.mobileOtpVerifyStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userGeneralDetailsService: (requestData) =>
      dispatch(userDetailsAction.userGeneralDetailsService(requestData)),
    // userPersonalDetailsService: (requestData) =>
    //   dispatch(userDetailsAction.userPersonalDetailsService(requestData)),
    getUserByID: (token, requestData) =>
      dispatch(commonAction.getUserByID(token, requestData)),
    updateUserDetails: (token, requestData) =>
      dispatch(
        userDetailsAction.updateUserPersonalGeneralDetails(token, requestData)
      ),
    addorRemoveFavorite: (requestData) =>
      dispatch(commonAction.favoriteOtherMember(requestData)),
    likeorDislike: (requestData) =>
      dispatch(commonAction.likeOtherMember(requestData)),
    InitiateNewChat: (requestData, token) =>
      dispatch(chatAction.InitiateNewChat(requestData, token)),
    getCountryService: () => dispatch(searchAction.getCountryService()),
    getStateService: (requestData) =>
      dispatch(searchAction.getStateService(requestData)),
    getCityService: (requestData) =>
      dispatch(searchAction.getCityService(requestData)),
    setCommonStatusNull: () => dispatch(commonAction.setCommonStatusNull()),
    getHairColorService: () => dispatch(searchAction.getHairColorService()),
    getEyeListService: () => dispatch(searchAction.getEyeListService()),
    verifyUniqueUser: (data) => dispatch(registerAction.verifyUniqueUser(data)),
    uniqueStatusNull: () => dispatch(registerAction.uniqueStatusNull()),
    updateMobileNo: (token, requestData) =>
      dispatch(
        userDetailsAction.updateMobileNumber(token, requestData)
      ),
    verifyMobileOtp: (token, requestData) =>
      dispatch(
        userDetailsAction.verifyMobileOtp(token, requestData)
      ),
    userDetailsDataNull: () => dispatch(userDetailsAction.userDetailsDataNull())
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfoCard);
