import axios from 'axios';
import { createAction } from 'redux-actions';

export const happyClientsInitiate = createAction('GET_HAPPY_CLIENTS_INITIATE');
export const happyClientsSuccess = createAction('GET_HAPPY_CLIENTS_SUCCESS');
export const happyClientsFailure = createAction('GET_HAPPY_CLIENTS_FAILURE');

export const recentClientsInitiate = createAction('GET_RECENT_CLIENTS_INITIATE');
export const recentClientsSuccess = createAction('GET_RECENT_CLIENTS_SUCCESS');
export const recentClientsFailure = createAction('GET_RECENT_CLIENTS_FAILURE');

export const getHappyClientsService = () => {

    return (dispatch) => {
      dispatch(happyClientsInitiate());
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL }circle_clients`)
        .then((response) => {
          if (response.data.STATUS) {                                                                                 
            dispatch(happyClientsSuccess(response.data.result));
          } else {
            dispatch(happyClientsFailure());
          }
        })
        .catch((error) => {
          dispatch(happyClientsFailure());
        });
    };
  };

  export const getRecentClientsService = () => {

    return (dispatch) => {
      dispatch(recentClientsInitiate());
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL }happy_clients`)
        .then((response) => {
          if (response.data.STATUS) {                                                                                 
            dispatch(recentClientsSuccess(response.data.result));
          } else {
            dispatch(recentClientsFailure());
          }
        })
        .catch((error) => {
          dispatch(recentClientsFailure());
        });
    };
  };