import React ,{useEffect,useState}from 'react';
import {
	Typography,
	useMediaQuery,
	Avatar,
	Grid,
	Button,
	styled,
	Badge,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Carousel } from 'react-responsive-carousel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTheme } from '@mui/material/styles';
import useStyles from '../../styles/landingPage';
import { getIsMobile } from '../../utils';
import { useHistory } from 'react-router';
import * as happyClientsAction from '../../redux/action/happyclientsAction';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		backgroundColor: '#44b700',
		color: '#44b700',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
	},
}));

const HappyUsers = (props) => {
	let history = useHistory();
	const classes = useStyles();
	const isMobile = getIsMobile();
	const theme = useTheme();
	const { t } = useTranslation();
const[happyClients,setHappyClients]=useState([])
	const getSlidePercentage = () => {
		let match = 100;
		// eslint-disable-next-line react-hooks/rules-of-hooks
		if (useMediaQuery(theme.breakpoints.up('md'))) {
			match = 25;
		} else {
			match = 100;
		}
		return match;
	};
	const movetoprofile = () => {
		window.scrollTo(0, 0)
		history.push('/login')
	}

	useEffect(() => {
		props.getHappyClientsService()
	}, [])

	useEffect(() => {
		if(props.happyClientsData.length>0){
			setHappyClients(props.happyClientsData)
		}
	
	}, [props.happyClientsData])
	const renderTestimonialCard = () => {
		return happyClients.map((item) => (
			<div className={classes.userSec} key={item}>
				{item.is_online?<StyledBadge
					overlap="circular"
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					variant="dot"
				>
					<Avatar
						alt="avatar"
						src={`${item.pictures}`}
						sx={{ height: '100px', width: '100px' }}
					/>
				</StyledBadge>:<Avatar
						alt="avatar"
						src={`${item.pictures}`}
						sx={{ height: '100px', width: '100px' }}
					/>}

				<Typography variant="body1" component="p" sx={{ marginTop: '10px' }}>
					{item.username}
				</Typography>

				<Typography variant="body2" component="p">
					<LocationOnIcon color="primary" className={classes.locationIcon} />
					{`${item.city_name},${item.state_name}`}
				</Typography>
				<Button
					variant="contained"
					color="primary"
					sx={{
						marginTop: '12px',
						marginBottom: '10px',
						textTransform: 'capitalize',
					}}
					onClick={() => movetoprofile()}
				>
					{t("viewProfile")}
				</Button>
			</div>
		));
	};

	return (
		<Grid
			container
			flexDirection="column"
			justifyContent="center"
			className={classes.happyUsersSec}
		>
			<Typography variant="h2" component="h2">
				{t("ourHappyusers")}
			</Typography>
			<Typography
				variant="body2"
				component="p"
				sx={{ marginTop: '15px', padding: isMobile ? '0px' : '0px 20vw' }}
			>
				{t("para3")}
			</Typography>

			<Carousel
				swipeable
				centerMode
				centerSlidePercentage={getSlidePercentage()}
				renderArrowPrev={(clickHandler) => (
					<div className={classes.leftArrow} onClick={clickHandler}>
						<ChevronLeftIcon className={classes.arrowIcons} />
					</div>
				)}
				renderArrowNext={(clickHandler) => (
					<div className={classes.rightArrow} onClick={clickHandler}>
						<ChevronRightIcon className={classes.arrowIcons} />
					</div>
				)}
				showArrows={false}
				showStatus={false}
				showIndicators={false}
				showThumbs={false}
			>
				{renderTestimonialCard()}
			</Carousel>
		</Grid>
	);
};
const mapStateToProps = (state) => {
	return {
		userToken: state.LoginReducer.userToken,
		happyClientsData: state.HappyClientsReducer.happyClientsData
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getHappyClientsService: () =>
			dispatch(happyClientsAction.getHappyClientsService()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HappyUsers);


